export default function Counter({ days, hours, minutes, seconds }){
    return (
        <div className="project__timer flex space-between">
            <h3>
                {days} <span>Days</span>
            </h3>
            <h3>
                {hours} <span>Hours</span>
            </h3>
            <h3>
                {minutes} <span>Mins</span>
            </h3>
            <h3>
                {seconds} <span>Secs</span>
            </h3>
        </div>
    );
};