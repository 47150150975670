import axios from 'axios';
import config from "../config"

class LoginService{
    auth(payload){
        let url = (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/auth/login");
        return new Promise((resolve,reject) => {
         axios.post(url,payload)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }
}

export default new LoginService;