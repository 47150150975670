import { useCountdown } from '../../hooks/useCountdown';
import Elapsed from './Elapsed'
import Counter from './Counter'


const CountDown = ({ start_date,end_date }) => {
  const [days, hours, minutes, seconds] = useCountdown(start_date,end_date);

  if (days + hours + minutes + seconds <= 0) {
    return <Elapsed />;
  } else {
    return (
      <Counter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountDown;