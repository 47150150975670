import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { entry_sidebar, self_pace_sidebar, student_sidebar } from "./route";
import { SidebarHeader } from "./SidebarHeader";

import { SidebarData } from "../../context/SidebarContext";
import { GlobalData } from "../../context/GlobalContext";

function Sidebar() {
  const [active, setActive] = useState(null);


  const { toggle, handleToggle,sidebar, setSideBar } = SidebarData();
  const { store } = GlobalData();

  const location = useLocation();

  useEffect(() => {
    let loginStatus = store?.DETAIL?.nav_route;
    let data;

    switch (loginStatus) {
      case "entry":
        setSideBar(entry_sidebar);
        break;
      case "self_pace":
   
      let _date1   = new Date(store.DETAIL.course_started);
      let _date2   = new Date();
      let _started = !( _date1 >= _date2);

      
    
        if(!_started){
          setSideBar(self_pace_sidebar);
        }
        else{
          data =[...self_pace_sidebar]

          data.map(e => e.show = true)
         
          setSideBar(data);
        }
       
        break;
      case "student":
        setSideBar(student_sidebar);
        break;
      default:
        // navigate('/');
        break;
    }
  }, [store]);

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  return (
    <aside className={`sidebar ${toggle ? "sidebar-menuactive " : ""}`}>
      <SidebarHeader profile={store?.DETAIL} />
      <ul className="sidebar_list">
        {sidebar.map((list) => (

          !list.show ? "" : <li key={list.id}> <Link
            to={list.link}
            className={active?.includes(list.link) ? "sidebar-active" : ""}
            onClick={handleToggle}>
            <button className="sb__link-icon">{list.icon}</button>
            {list.title}
          </Link>
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default Sidebar;
