import { ModuleCard } from "../../components/cards/Card";
import Img from "../../assets/img/html_img.png";
import Img1 from "../../assets/img/css_img.png";
import { Link } from "react-router-dom";

export default function CompletedModules() {
  const modules = [
    {
      id: 1,
      img: Img,
      title: "HTML & CSS",
      status: "Completed",
      topicCount: "7 / 20",
      duration: "3 weeks",
      start_date: "July 10 2023",
      end_date: "Aug 02 2023",
    },
    {
      id: 2,
      img: Img1,
      title: "CSS Lab Class",
      status: "Completed",
      topicCount: "7 / 20",
      duration: "3 weeks",
      start_date: "July 10 2023",
      end_date: "Aug 02 2023",
    },
  ];

  return (
    <>
      <div className="modul__compl_divider">
        <p className="modul_divider">Completed Courses</p>
      </div>
      <div className="module__grid">
        {modules.map((module) => (
          <Link to={`/modules/${module.id}`} key={module.id}>
            <ModuleCard mod={module} />
          </Link>
        ))}
      </div>
    </>
  );
}
