import axios from 'axios';
import config from "../config"

class EntryProject {

    getCurrentProject(id) {
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:id/assessments").bind(id).query({ type: "current_project" });
       
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err.message))
        })

    }

    submitAssessment(data) {
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/assessments/submit");
       
        return new Promise((resolve, reject) => {
            axios.post(url,data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err.message))
        })

    }
}

export default new EntryProject;