import { useRef, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useUpload } from 'rework-upload';
import config from "../config"
import CountDown from '../../components/countdown';
import EntryProjectService from "./EntryProjectService";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from 'react-toastify';

export const ProjectUpload = ({ store,project,student,setSubmission,submission }) => {

    const [uploadName, setUploadName] = useState("");
    const [file, setFile] = useState("");
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);

    const progressRef = useRef(0)
    const waveRef = useRef(0)
    const waveContainerRef = useRef(0)

    const WAVE_TOP = 66;
    const start_date= new Date(project?.start_date).getTime();
    const end_date = new Date(project?.end_date).getTime();
  
    
    const ASSESSMENT_TYPE = "project";
   

    const URL =  (config.MODE=="DEV" ? config.DEV : config.PROD)
    .concat("/uploads?m=students/:a/assessment/:b")
    .bind(student?.student_id, ASSESSMENT_TYPE);


    const setUpload = (e) => {
        let file = e.target.files[0];
        setFile(file);
        setUploadName(file.name);
    }

    const handleBtn = () => {
        
        if(submission == 0){
             
            toast("You have exhuasted your submission trails, contact admin for submission.", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }

        if (uploadName) {

            setUploading(true);
            setLoading(true);

            waveContainerRef.current.style.borderColor = "#3db9e7";
            waveContainerRef.current.style.color = "#fff"; // color test //#2527338f

            let payload = { file: file, url: URL };


            useUpload(payload, (err, data) => {

                if (data) {
                    let wave = WAVE_TOP - 2 * data.pct;

                    waveRef.current.style.top = wave + 'px';
                    progressRef.current.innerHTML = data.msg;

                    if (data.pct >= 60) {

                        waveContainerRef.current.style.color = "#fff";
                        waveContainerRef.current.style.backgroundColor = "#3db9e7";
                    }

                    if (err) return;
                    if (data.completed) {

                        let payload = {
                            filename:data.filename,
                            user_id:student?.id,
                            phase_id:project.current_phase,
                            assessment_id:project.id
                        }
                        
                        EntryProjectService
                        .submitAssessment(payload)
                        .then(res=>{

                            setLoading(false);
                            submission -= 1
                            if(submission <= 0) submission = 0;

                            setSubmission(submission)
                            
                            toast("Thank you for your submission", {
                                position: "bottom-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                              
                        });

                        setUploading(false);
                    }
                }

            });

        }

    }

    return (
        <div className="project__card project__card2">
          <form>
                <div className="project__card2-title">
                  <h2>Project Elapse Timer</h2>
                  <CountDown 
                    start_date={start_date}
                    end_date={end_date}
                    />
                </div>
                <hr />
                <div className="project__card-body lp__details-upload">
                  <label htmlFor="p_upload" className="lp__upload div" ref={waveContainerRef}>
                    <IoCloudUploadOutline className="lp__upload-icon" />
                    <p>{uploadName ? uploadName : "Click to upload Project file"}</p>
                    <span ref={progressRef}></span>
                    {uploading ? <div className="flux" ref={waveRef}></div> : ""}
                
                  </label>
                  <input 
                    type="file" 
                    id="p_upload" 
                    onChange={(e) => setUpload(e)} />
                </div>
                <div className="assess_card-btn">
                  <button 
                    disabled={loading ? "disabled" : null}
                    className="button primary shadow" 
                    type="button" 
                    onClick={handleBtn}>
                        {loading ? <BeatLoader color="#0a6bfb" /> : "Submit"}
                    </button>
                </div>
          </form>
        </div>
    );
};