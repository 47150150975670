import { Navigation } from "../../components/navigation/Navigation";
import CompletedModules from "./CompletedModules";
import CurrentModules from "./CurrentModules";
import "./style.css";

function CoursesModules() {
  return (
    <>
      <Navigation title="Modules" />
      <div className="space-top db__height">
        <div className="courses_modules">
          <CurrentModules />
          <CompletedModules />
        </div>
      </div>
    </>
  );
}

export default CoursesModules;
