import { useState } from "react";
import { IoCloudUploadOutline, IoPersonCircleOutline } from "react-icons/io5";
import Img from "../../assets/img/Rework-Academy-Logo.png";
import "./style.css";

function PassportUpload() {
  const [dp, setDp] = useState("");
  const [dpName, setDpName] = useState("");
  const [sign, setSign] = useState("");
  const handleDp = (e) => {
    setDp(URL.createObjectURL(e.target.files[0]));
    setDpName((prev) => (prev = e.target.files[0].name));
  };
  return (
    <div className="lp__container">
      <div className="lp__content form">
        <form>
          <div className="lp__passport">
            <div className="lp__header">
              <img src={Img} alt="Logo" />
              <h2>Passport Upload</h2>
              <p>Kindly upload a passport photograph of yourself.</p>
            </div>
            <div className="dp__body">
              <label htmlFor="dp_file">{dp ? <img src={dp} alt="Passport" /> : <IoPersonCircleOutline className="dp__body-icon" />}</label>
              <input type="file" id="dp_file" onChange={handleDp} />
            </div>
            <div className="lp__details-upload">
              <label htmlFor="dp_file" className="lp__upload">
                <IoCloudUploadOutline className="lp__upload-icon" />
                <p>{dpName ? dpName : "Click to upload your passport"}</p>
              </label>
            </div>
          </div>
          <div className="lp__signature">
            <h4>Take a photo of your signature on a plain white paper, and upload</h4>
            <div className="lp__details-upload">
              <label htmlFor="signature_file" className="lp__upload">
                <IoCloudUploadOutline className="lp__upload-icon" />
                <p>{sign ? sign : "Click to upload your Signature"}</p>
              </label>
              <input type="file" id="signature_file" onChange={(e) => setSign(e.target.files[0].name)} />
            </div>
          </div>
          <div className="lp__btn">
            <button className="button primary shadow">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PassportUpload;
