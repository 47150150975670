import { PageRedirect, PageTitle, Route } from "react-paget";
import { LoginHeader } from "./LoginHeader";
import { GlobalData } from "../../context/GlobalContext";

import LoginForm from "./LoginForm";
import "./style.css";

function Login() {

  const { store, } = GlobalData();

  return (
    <PageRedirect
      condition={store}
      route={store ? Route.PREV_PAGE : '/'}
      redirect >
      <PageTitle title="Portal-Login">
        <div className="login__container">
          <div className="form login__content">
            <LoginHeader />
            <LoginForm />
          </div>
        </div>
      </PageTitle>
    </PageRedirect>
  );
}
export default Login;
