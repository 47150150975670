
import { useEffect, useState, useRef } from "react";
import "videojs-markers";
import "videojs-hotkeys";
import "videojs-landscape-fullscreen";
import videojs from "video.js";

import "./video.custom";
import "./video.css";
import "./format.video.css";
import "./style.css";

import { VideoData } from "../../context/VideoContext";


function Player(props) {

  // const init_settings = {
  //   volume: 100,
  //   playback_speed: 1,
  //   current_time: 0,
  //   pause_on_tab_change: false,
  //   markers: [],
  // };

  let videoReference = useRef(null);
  let playerReference = useRef(null);


  let [player_ref, setPlayerRef] = useState();
  let [is_played, setIsPlayed] = useState(false);
  let { options } = VideoData()
  let interval;

  // const [player_setting, setPlayerSetting] = useState(init_settings);

  let onReady = (player, elem) => {
    setPlayerRef(player);
    // console.log(player.controlBar);
    // Add the custom fullscreen button to the control bar
    // player.controlBar.addChild(
    //   "Fullscreen",
    //   {},
    //   player.controlBar.children().length - 1
    // );
    // player.controlBar
    //   .el()
    //   .insertBefore(
    //     player.controlBar.getChild("Fullscreen").el(),
    //     player.controlBar.el().lastChild
    //   );

    // // Add markers to the player
    // player.markers({
    //   markerStyle: {},
    //   markers: [
    //     { time: 10, text: "I would love to see all the information you add" },
    //     { time: 120, text: "This is to enable me add a new info" },
    //     {
    //       time: 300,
    //       text: "Getting more on the course is based on time and effort",
    //     },
    //     // Add more markers as needed
    //   ],
    // });

    // console.log(player.controlBar)
    // player.controlBar.removeChild("audioTrackButton");
    // player.controlBar.removeChild("SubsCapsButton");
    player.controlBar.removeChild("playToggle");
    player.controlBar.removeChild("timeDivider");
    player.controlBar.removeChild("playToggle");
    player.controlBar.removeChild("volumePanel");
    player.controlBar.removeChild("FullscreenToggle");
    player.controlBar.removeChild("playbackRateMenuButton");
    player.controlBar.removeChild("durationDisplay");
    player.controlBar.removeChild("remainingTimeDisplay");
    player.controlBar.removeChild("fullscreenToggle");

    // player.controlBar.addChild("Academy", {});
    player.controlBar.addChild("playToggle");
    player.controlBar.addChild("Rewind");
    player.controlBar.addChild("playbackRateMenuButton");
    player.controlBar.addChild("Forward");
    player.controlBar.addChild("volumePanel");

    player.controlBar.addChild("remainingTimeDisplay");
    player.controlBar.addChild("timeDivider");
    player.controlBar.addChild("durationDisplay");
    player.controlBar.addChild("Next", { next: props.onNextVideo }, 1);
    player.controlBar.addChild("fullscreenToggle");
    player.controlBar.addChild("fullscreenToggle");

    // Listen for the 'ready' event
    player.ready(() => {
      // Set the currentTime to the desired start time
      player.currentTime(props.start_time);
      // player.qualityLevels();
    });

    player.on("loadedmetadata", () => {
      // console.log("Metadata loaded:", player.duration());
    });

    player.on("durationchange", () => {
      props.onDurationChanged(player.duration());
    });

    player.on("loadeddata", () => {
      props.onVideoLoaded()
    });

    player.on("canplaythrough", () => {
      props.onCanPlayThroughChange()

    });

    player.on("ratechange", () => {
      // the rate change changes here
      props.onPlayBackRateChange(player.playbackRate())
      // console.log("Playback rate changed:", player.playbackRate());
    });

    player.on("fullscreenchange", () => {
      // console.log("Fullscreen mode changed");
    });

    //Triggered when the browser is unable to fetch the media data, typically due to a slow network.
    player.on("stalled", () => {
      console.log("Media playback is stalled");
    });

    //Triggered when the browser stops fetching the media data.
    player.on("abort", () => {
      console.log("Media loading aborted");
    });

    // handling video player
    player.on("waiting", () => {
      props.onVideoWait()
    });

    player.on("play", () => {
      setIsPlayed(true);

      interval = setInterval(() => {
        props.onVideoPlay(player.currentTime())
        // const currentTime = player.currentTime();
        // console.log("Current Time:", currentTime);
      }, 1000);
      // console.log("Video is playing");
    });

    player.on("pause", () => {
      clearTimeout(interval);
      props.onVideoPaused();
      // console.log("Video is paused");
    });

    player.on("dispose", () => {
      // console.log("Video player will dispose");
    });

    //Triggered when the text track cues change.
    player.on("cuechange", () => {
      // console.log("Text track cues changed");
    });

    //Triggered when the playback starts after the media has been loaded.
    player.on("loadedstart", () => {
      // console.log("Playback started after media load");
    });

    player.on("pictureinpicturechange", () => {
      // console.log("Picture-in-picture mode changed");
    });

    //Triggered when the player has downloaded all media data.
    player.on("loadedalldata", () => {
      // console.log("All media data loaded");
    });

    //Triggered when the active text track changes.
    player.on("texttrackchange", () => {
      console.log("Active text track changed");
    });

    // Triggered when the volume changes.
    player.on("volumechange", () => {
      // console.log("Volume changed:", player.volume());
      props.onVolumeChange(player.volume())
    });

    //Triggered when the video quality changes (useful for adaptive streaming scenarios).
    player.on("qualitychange", () => {
      // console.log("Video quality changed");
    });

    //Triggered when the playback rate changes (e.g., slow motion or fast forward).
    player.on("playbackratechange", () => {
      // console.log("Playback rate changed:", player.playbackRate());
      props.onPlayBackRateChange(player.playbackRate())
    });

    //Triggered when the active audio or video track changes.
    player.on("trackschange", () => {
      console.log("Active audio or video track changed");
    });

    //Triggered when the player exits fullscreen mode.
    player.on("exitFullscreen", () => {
      // console.log("Exited fullscreen mode");
    });

    // Event listener for 'ended' event
    player.on("ended", () => {
      // console.log("Video has ended");
      props.onVideoEnded(player.currentTime())
    });
  };

  // Remove the context menu on the video player
  let handleContextMenu = (event) => {
    event.preventDefault();
  };

  useEffect(() => {

    // Initializing video.js player
    if (!playerReference.current) {
      let videoElement = videoReference.current//document.getElementById('videoReference');
      if (!videoElement) return;

      // Attach the event listener to the video element
      videoElement.addEventListener("contextmenu", handleContextMenu);

      let player = playerReference.current = videojs(
        videoElement,
        options,
        () => {
          // videojs.log("Video player is ready");
          onReady && onReady(player, videoElement);
        }
      );

    // console.log(player.getPlayer())
    // console.log(player.currentSources())
    // player.landscapeFullscreen();
    
    }


  }, [options]);


  // wrap player with data-vjs-player` attribute
  // so no additional wrapper are created in the DOM
  return (
    <div data-vjs-player>
      <video ref={videoReference} className="video-js vjs-big-playcentered" />
    </div>
  );

}

export default Player;
