
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {
  IoCodeSharp,
  IoPlayOutline,
  IoChevronDownSharp,
  IoLockClosedOutline,
  IoRadioButtonOffSharp,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";


function LessonDetailsContent({
  name,
  module_id,
  url,
  setActiveTab,
  setNextTab,
  activeTab,
  module_lesson }) {

  // const [activeAccordion, setActiveAccordion] = useState(activeTab?.chapter_id);
  // const [activeLesson, setActiveLesson] = useState(activeTab?.id);
  const [activeAccordion, setActiveAccordion] = useState();
  const [activeLesson, setActiveLesson] = useState();


  const handleActiveAccordion = (id) => {
    setActiveAccordion((prev) => (prev = id));
  };

  const handleActiveTab = (data, next) => {

    setActiveTab((prev) => (prev = data));
    setActiveLesson((prev) => (prev = data.id));
    setNextTab({ ...next })
  };


  useEffect(() => {

    setActiveAccordion(activeTab?.chapter_id)
    setActiveLesson(activeTab?.id)

  }, [activeTab])


  return (
    <div className="LessonDetailsContent">
      <div className="LD_content_header">
        <h4>Module Content</h4>
      </div>
      <div className="LD_content--grid">
        {module_lesson.length ? (
          module_lesson.map((lesson) => (
            <div className="LD_content--module" key={lesson.id}>
              <button className="LD_content--module-header" onClick={() => handleActiveAccordion(lesson.id)}>
                <h4>{lesson.title}</h4>
                <p>{lesson.lectures.length} Lessons</p>
                <div className="LD_content--header-icon">
                  <IoChevronDownSharp />
                </div>
              </button>
              <div className={`LD_content--module-content ${activeAccordion === lesson.id ? "active" : ""}`}>
                <ul>
                  {lesson.lectures.map((content, i) => {

                    let url = content.url

                    return <li
                      key={content.id}
                      className={`${activeLesson === content.id ? "active" : ""}`}
                      onClick={() => handleActiveTab(content, lesson.lectures[i + 1])}>
                      <Link
                        className={`${activeLesson === content.id ? "active" : ""}`}
                        to={`/lessons/${name}/${url}?id=${module_id}`}>
                        {
                          content.locked ? <IoLockClosedOutline /> :
                            (content.completed ?
                              <IoCheckmarkCircleOutline className={"LD_content_icon_active"} /> :
                              <IoRadioButtonOffSharp />)
                        }
                        {content.type === "lesson" ? <IoPlayOutline /> : <IoCodeSharp />}
                        <span>
                          {i + 1}. <strong>{content.type}:</strong> {content.title}
                        </span>
                      </Link>
                    </li>
                  }

                  )}
                </ul>
              </div>
            </div>
          ))
        ) : (
          <p>No lesson available</p>
        )}
      </div>
    </div>
  );
}

export default LessonDetailsContent;
