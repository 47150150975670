import videojs from "video.js";

class ReworkAcademyIcon extends videojs.getComponent("Button") {
  constructor(player, options) {
    super(player, options);
    this.controlText("Rework Academy");
    this.el().innerHTML =
      "<img src='https://reworkacademy.co/favicon.ico' width='40'/>";
    this.addClass("vjs-custom-button");
    // videojs.getComponent("Button").call(this, player, options);
  }

  handleClick() {
    // Add your custom click logic here
    console.log("Custom button clicked!");
  }
}

class Rewind extends videojs.getComponent("Button") {
  constructor(player, options) {
    super(player, options);
    this.player = player;
    this.controlText("Rewind");
    this.el().innerHTML =
      "<span class='vjs-icon-placeholder'></span><span class='num'>5</span>";
    this.addClass("vjs-rewind-control");
  }

  handleClick() {
    // Add your custom click logic here
    this.player.currentTime(this.player.currentTime() - 5);
  }
}

class Forward extends videojs.getComponent("Button") {
  constructor(player, options) {
    super(player, options);
    this.player = player;
    this.controlText("Forward");
    this.el().innerHTML =
      "<span class='vjs-icon-placeholder'></span><span class='num'>5</span>";
    this.addClass("vjs-forward-control");
  }

  handleClick() {
    // Add your custom click logic here
    this.player.currentTime(this.player.currentTime() + 5);
  }
}

class Next extends videojs.getComponent("Button") {
  constructor(player, options) {
    super(player, options);

    this.player = player;
    this.options = options;

    this.controlText("Next Lesson");
    this.el().innerHTML = "<span>Continue </span>";
    this.addClass("vjs-next-control");
  }

  handleClick() {
    this.options.next(this.player);
  }
}

// Register the custom button component with Video.js
videojs.registerComponent("Academy", ReworkAcademyIcon);

videojs.registerComponent("Rewind", Rewind);
videojs.registerComponent("Forward", Forward);
videojs.registerComponent("Next", Next);
