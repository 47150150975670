import "./style.css";
import bgPhoto from "../../assets/img/user-circle.png";
import ProfileAbout from "./ProfileAbout";
import ProfileCourse from "./ProfileCourse";
import ProfileEducation from "./ProfileEducation";
import ProfileLanguage from "./ProfileLanguage";
import ProfileLocation from "./ProfileLocation";
import ProfileName from "./ProfileName";
import ProfileProject from "./ProfileProject";
import ProfileSkills from "./ProfileSkills";
import ProfileSocialMedia from "./ProfileSocialMedia";
import { ProfileData } from "../../context/ProfileContext";
import { Navigation } from "../../components/navigation/Navigation";
import EditHeader from "./EditHeader";
import { Link } from "react-router-dom";

import { GlobalData } from "../../context/GlobalContext";
import ProfileService from "./ProfileService";
import {useEffect,useState} from 'react'
import { PageRedirect, PageTitle, Route } from "react-paget";
import Loading from "../../components/Loading";

function Profile() {

  let { 
    photo,
    setCourses,
    setProject,
    setSocialMedia,
    setName,
    name,
    setLocation,
    setEducation,
    setLanguage,
    setLanguages,
    setSkills
   } = ProfileData();

   let { store } = GlobalData();
   let detail = store?.DETAIL;
   let user_id = detail?.id ;

   let [current_course,setCurrentCourse] = useState("");
   let [profile,setProfile] = useState({});
   let [degrees,setDegree] = useState([]);
   let [page_load,setPageLoad] = useState(true);

  useEffect(()=>{

    if(user_id){
      ProfileService.getProject(user_id)
      .then(e=>{
  
        if(Array.isArray(e)){
          let projects = e.map(p=>({
            id:p.id,
            title:p.title,
            status:p.status,
            url:p.url,
            start_date:p.start_date,
            end_date:p.end_date,
            descrp:p.descp
          }));
          setProject(projects);
        }
      
  
      });
  
      ProfileService.getProfile(user_id)
      .then(e=>{
      console.log(e)
        let skills = [];
        let courses = e?.courses?.map(c=>({
          id:c.id,
          course:c.name,
          status:c.status
        })) || [];
        
        e.skill?.map(x=>{
          x.skills.map(x=> skills.push(x))
        });
  
        setProfile(e);
  
        setCourses(courses);
        setLanguage([...e?.languages]);
        setSocialMedia({...e?.social_links});
        setName(`${e.first_name} ${e.last_name}`);
        setLocation(e.location);
        setSkills(skills);
        setEducation(e.certificate_default);
        setCurrentCourse(e.current_course);

        setPageLoad(false)
  
      });
  
      ProfileService.getLanguages()
      .then(e=>{
        setLanguages(e);
      });
  
      ProfileService.getDegree()
      .then(e=>{
        setDegree(e);
      });
    }

  },[store])

  return (
    <PageTitle title="Portal-Profile">
      <Navigation title="Profile" />
     {
      page_load? <Loading/> :  <div className="db__height">
      <div className="profile">
        <div className="profile--banner">
          <div className="profile--inner-container">
            <div className="profile--photo">
              <img src={photo ? photo : bgPhoto} alt="Profile Photo" />
              <div className="profile--photo-btn">
                <input type="file" id="profilePhoto" />
                <label htmlFor="profilePhoto">Upload Photo</label>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-sub-section">
          <div className="profile--name">
            <h2>{name}</h2>
            <h4>{current_course}</h4>
          </div>
        </div>
        <div className="profile--container">
          <div className="profile-sections">
            <div className="profile--fullname profile-sects">
              <ProfileName />
            </div>
            <div className="profile--course profile-sects">
              <ProfileCourse />
            </div>
            <div className="profile--location profile-sects">
              <ProfileLocation user_id={user_id} profile={profile} setProfile={setProfile}/>
            </div>
            <div className="profile--language profile-sects">
              <ProfileLanguage user_id={user_id}/>
            </div>
            <div className="profile--education profile-sects">
              <ProfileEducation degrees={degrees} user_id={user_id}/>
            </div>
            <div className="profile--social-media profile-sects">
              <ProfileSocialMedia user_id={user_id} />
            </div>
          </div>
          <div className="profile-sections">
            <div className="profile--about profile-sects">
              <ProfileAbout user_id={user_id} profile={profile} setProfile={setProfile}/>
            </div>
            <div className="profile--skills profile-sects">
              <ProfileSkills />
            </div>
            <div className="profile--projects profile-sects">
              <ProfileProject/>
            </div>
            <div className="profile--certificates profile-sects">
              <EditHeader heading={"Certificates"} />
              <Link to="/certificates">View certificates</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
     }
    </PageTitle>
  );
}

export default Profile;
