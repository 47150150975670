import { ModuleCard } from "../../components/cards/Card";
import Img1 from "../../assets/img/github_img.png";
import Img2 from "../../assets/img/bootstrap.jpeg";
import Img3 from "../../assets/img/sass_img.jpg";
import Img4 from "../../assets/img/js_img.png";
import Img5 from "../../assets/img/jquery_img.png";
import { Link } from "react-router-dom";

function CurrentModules() {
  const modules = [
    {
      id: 1,
      img: Img1,
      title: "GitHub",
      status: "Current",
      topicCount: "7 / 20",
      duration: "3 weeks",
      start_date: "July 10 2023",
      end_date: "Aug 02 2023",
    },
    {
      id: 2,
      img: Img2,
      title: "Bootstrap",
      status: "Pending",
      topicCount: "7 / 20",
      duration: "3 weeks",
      start_date: "July 10 2023",
      end_date: "Aug 02 2023",
    },
    {
      id: 3,
      img: Img3,
      title: "Sass",
      status: "Pending",
      topicCount: "7 / 20",
      duration: "1 day",
      start_date: "July 10 2023",
      end_date: "Aug 02 2023",
    },
    {
      id: 4,
      img: Img4,
      title: "Beginner JavaScript",
      status: "Pending",
      topicCount: "7 / 20",
      duration: "3 weeks",
      start_date: "July 10 2023",
      end_date: "Aug 02 2023",
    },
    {
      id: 5,
      img: Img5,
      title: "jQuery & Ajax",
      status: "Pending",
      topicCount: "7 / 20",
      duration: "2 weeks",
      start_date: "July 10 2023",
      end_date: "Aug 02 2023",
    },
  ];

  return (
    <>
      <div className="module__grid">
        {modules.map((module) => (
          <Link to={`/modules/${module.id}`} key={module.id}>
            <ModuleCard mod={module} />
          </Link>
        ))}
      </div>
    </>
  );
}

export default CurrentModules;
