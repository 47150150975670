import { createContext, useState } from "react";
import { useContext } from "react";

export const ProfileContext = createContext();
export const ProfileData = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [courses, setCourses] = useState([
    {
      id: 1,
      course: "Web Development with Node JS",
      status: "ongoing",
    },
    {
      id: 2,
      course: "Data Science with Python",
      status: "completed",
    },
  ]);
  const [project, setProject] = useState([
    {
      id: 1,
      title: "Apple Website",
      url: "applewebite.com",
      status: "completed",
      start_date: "10 Feb 2022",
      end_date: "14 Feb 2022",
      descrp: "",
    },
    {
      id: 2,
      title: "Poketo Website",
      url: "poketo.com",
      status: "ongoing",
      start_date: "10 Feb 2022",
      end_date: "14 Feb 2022",
      descrp: "",
    },
  ]);
  const [socialMedia, setSocialMedia] = useState({
    twitter: "JohnPence",
    facebook: "John Pence",
    instagram: "",
    linkendin: "",
  });
  const [languages,setLanguages] = useState([
    {
      id: 1,
      name: "English",
      code: "en",
    },
  ])

  const [photo, setPhoto] = useState(null);
  const [about, setAbout] = useState("");
  const [name, setName] = useState("Michael Jonah Pence");
  const [location, setLocation] = useState("Abuja, Nigeria");
  const [education, setEducation] = useState("Bachelor's Degree");
  const [skills, setSkills] = useState(["HTML", "CSS", "Git", "Javascript", "React.JS", "Bootstrap", "SASS", "Github"]);
  const [language, setLanguage] = useState([
    {
      name:"English",
      "fluency":"Read & Speak only",
    },
    {
      name:"French",
      "fluency":"speak only"
    },
    {
      name:"German",
      "fluency":"Read & write only"
    },
    {
      name:"Igbo",
      "fluency":"speak only"
    }
    
  ]);

  const vals = {
    courses,
    setCourses,
    project,
    setProject,
    socialMedia,
    setSocialMedia,
    photo,
    setPhoto,
    about,
    setAbout,
    name,
    setName,
    location,
    setLocation,
    education,
    setEducation,
    skills,
    setSkills,
    language,
    setLanguage,
    languages,
    setLanguages
  };
  return <ProfileContext.Provider value={vals }>{children}</ProfileContext.Provider>;
};
