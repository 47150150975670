
import './style.css'

 export default function Loading() {
    return (
      <div className="loading_screen">
        <div className="loading_content">
          <div className="loading_cover"></div>
        </div>
      </div>
    );
  };
  