import { IoMenu, IoNotifications, IoClose, IoPersonCircleSharp } from "react-icons/io5";
import { SidebarData } from "../../context/SidebarContext.js";
import { Link } from "react-router-dom";
import { GlobalData } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import Trove from "react-trove";
import { useCookies } from 'react-cookie';

export let Navigation = ({ title }) => {
  let date = new Date().toUTCString().slice(0, 16);
  let { toggle, handleToggle } = SidebarData();
  let [,removeCookie] = useCookies();
  let navigate = useNavigate();

  // let { store } = GlobalData();

  let handleLogout = () => {
    
    Trove.remove();
    removeCookie('logged','');
    removeCookie('paid','');
    localStorage.clear();
  }

  return (
    <div className="nav_bar">
      <div className="flex space-between">
        {toggle ? <IoClose className="navbar__menu" onClick={handleToggle} /> : <IoMenu className="navbar__menu" onClick={handleToggle} />}
        <div className="navbar__left">
          <h2>{title}</h2>
          <h3>{date}</h3>
        </div>
        <div className="flex navbar__profile">
          <Link to="/notifications" className="navbar_notification">
            <IoNotifications />
          </Link>
          <Link to="/profile" className="navbar_profile" title="Profile">
            <IoPersonCircleSharp />
          </Link>
          <div className="navbar_logout">
            <button onClick={handleLogout}>Logout</button>
          </div>
        </div>
      </div>
    </div>
  );
};

// *312*800#