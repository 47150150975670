import axios from 'axios';
import config from "../config"

class DashboardService{

    info(student_id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id/info").bind(student_id);
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    checklist(student_id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id/checklist").bind(student_id);
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getCourseProgress(user_id,course_id){
      let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/course-progress").query({user_id,course_id});
      return new Promise((resolve,reject) => {
       axios.get(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
  }


    getNotification(student_id,size){
      let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id/notice").bind(student_id).query({limit:size});
      return new Promise((resolve,reject) => {
       axios.get(url)
       .then(res => {
         let result = res.data.data;
          resolve(result);
       })
       .catch(err => reject(err.message));
     })
  }

}

export default new DashboardService;