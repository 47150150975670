
import Player from "../../components/video";
import { IoTimeOutline } from "react-icons/io5";
import LessonDetailService from './LessonDetailService';
import { VideoData } from "../../context/VideoContext";
import { useState } from 'react'

export default function LessonVideo({
  nextContent,
  activeTab,
  setIndex,
  setActiveTab
}) {

  let [start_time, setStarTime] = useState(0)
  let {
    video_url,
    playlist,
    options,
    setVideoUrl,
    setOptions
  } = VideoData()


  let onVolumeChange = (e) => {
    // console.log(e)
  }

  let onVideoPlay = (e) => {

    // // check to see if the video has started playing
    // let start_lesson_data;
    // let set_lesson_track_data;

    // if (!activeTab.started) {

    //   start_lesson_data = { user_id, lesson_id: activeTab.id }

    //   LessonDetailService
    //     .startLesson(start_lesson_data)
    //     .then(res => {

    //       let d = { ...activeTab, started: true, started_at: new Date().toJSON() }
    //       setActiveTab(d)
    //     });

    // } else {
    //   countdown--;
    //   if (countdown <= 0) {
    //     countdown = init_value;
    //     id++;
    //   }

    //   if (id === init_value) {
    //     // console.log("usr",user_id)
    //     set_lesson_track_data = { user_id, lesson_id: activeTab.id, track_location: e }


    //     LessonDetailService
    //       .setLessonTrackLocation(set_lesson_track_data)
    //       .then(res => {
    //         console.log(res)
    //       })

    //     id = 0
    //   }
    // }

  }

  let onVideoEnded = (e) => {
    // console.log('next video')


    // set track loation
    // LessonDetailService
    // .setLessonTrackLocation(set_lesson_track_data)
    // .then(res => {

    //   console.log("set-track:",res)
    // })

    // end video

    // markAsEnded()

  }

  let markAsEnded = () => {

    // let complete_lesson_data = { user_id, lesson_id: activeTab.id }
    // let set_next_lesson_data = { user_id, lesson_id: activeTab.next_lesson_id }

    // LessonDetailService
    //   .completeLesson(complete_lesson_data)
    //   .then(res => {
    //     // console.log("complete-video:", res)

    //     if (activeTab.next_lesson_id) {

    //       //set next video
    //       LessonDetailService
    //         .setNextLesson(set_next_lesson_data)
    //         .then(res => {
    //           // console.log("set-next-video:", res)
    //         });

    //     }

    //   })

  }

  let onPlayBackRateChange = (e) => {
    // console.log(e)
  }

  let onVideoLoaded = () => {
    // console.log("First frame loaded");
  }

  let onVideoWait = (e) => {
    // console.log("Video Player is waiting");
  }

  let onVideoPaused = () => {
    // console.log('paused')
    // clearInterval(video_track_location);
  }

  let onDurationChanged = (e) => {
    // console.log('changed')
  }

  let onCanPlayThroughChange = () => {
    // console.log("Can play through without interruptions");
  }

  let onNextVideo = (player) => {

    let data = nextContent()

    player.src(data)
    player.play();

  }



  return <div className="lesson_details--banner">
    <div className="LD_banner_video">
      <Player
        video={video_url}
        start_time={start_time}
        onVideoEnded={onVideoEnded}
        onVolumeChange={onVolumeChange}
        onPlayBackRateChange={onPlayBackRateChange}
        onCanPlayThroughChange={onCanPlayThroughChange}
        onVideoPlay={onVideoPlay}
        onVideoPaused={onVideoPaused}
        onVideoLoaded={onVideoLoaded}
        onVideoWait={onVideoWait}
        onDurationChanged={onDurationChanged}
        onNextVideo={onNextVideo}
      />

      {/* <video src={activeTab.video} controls controlsList="nodownload"></video> */}
    </div>
    <div className="LD_banner--content">
      <div className="LD_banner--header">
        <h4>{activeTab.title}</h4>
        <p>
          <IoTimeOutline />
          {activeTab.duration} min
        </p>
      </div>
      <p className="LD_banner--message">{activeTab.description}</p>
    </div>
  </div>
}
