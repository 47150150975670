import { useState } from "react";
import { Link } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { GlobalData } from "../../context/GlobalContext";
import { useCookies } from 'react-cookie';

import LoginService from "./LoginService";
import Trove from "react-trove";

export default function LoginForm() {
  let [isLoading, setIsLoading] = useState(false);
  let [error, setError] = useState(false);
  let [message, setMessage] = useState("");
  let [credential, setCredential] = useState({ username: "", passwd: "" });
  let [pwdStatus, setPwdStatus] = useState("password");
  let [, setCookie] = useCookies(['logged']);

  let { store } = GlobalData();

  let navigate = useNavigate();

  let togglePwd = () => setPwdStatus((prev) => (prev === "password" ? (prev = "text") : (prev = "password")));

  let handleOnChange = (e) => {
    let { name, value } = e.target;
    setCredential({ ...credential, [name]: value });
  };

  let handleLogin = async () => {
    setIsLoading(true);

    let response = await LoginService.auth(credential);

    if (response.code === 404) setError(true);
    else setError(false);

    setIsLoading(false);
    setMessage(response.msg);


    if (
      (response.data?.learning_mode === "SELF_PACE") && 
       response.data?.reg_status === "COMPLETED") {
      response.data.nav_route = "self_pace";
      Trove.save("DETAIL", response.data);

      setCookie("logged",true)
      setCookie("completed",true)
      setCookie("paid",response.data.has_made_payment)
      navigate("/dashboard");

      return;
    }

    if (
      (response.data?.learning_mode === "PHYSICAL" || 
       response.data?.learning_mode === "VIRTUAL") && 
       response.data?.reg_status === "COMPLETED") {
      response.data.nav_route = "student";
      Trove.save("DETAIL", response.data);

      setCookie("logged",true)
      setCookie("completed",true)
      setCookie("paid",response.data.has_made_payment)
      navigate("/dashboard");

      return;
    }

    if (
       (response.data?.learning_mode === "PHYSICAL" || 
        response.data?.learning_mode === "VIRTUAL") && 
        response.data?.reg_status !== "COMPLETED") {
      response.data.nav_route = "entry";
      Trove.save("DETAIL", response.data);

      setCookie("logged",true)
      setCookie("completed",false)
      setCookie("paid",response.data.has_made_payment)
      navigate("/entry-project");
      return;
    }

   
  };

  return (
    <form className="login-form">
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input type="email" placeholder="Email address" tabIndex="1" name="username" onChange={(e) => handleOnChange(e)} />
      </div>
      <div className="form-group">
        <div className="flex space-between">
          <label htmlFor="password">Password</label>
          <Link to="/forget" className="forgot" tabIndex="4">
            Forgot password?
          </Link>
        </div>
        <div className="login-pwd">
          <input 
            type={pwdStatus} 
            placeholder="Password" 
            tabIndex="2" 
            name="passwd" 
            onChange={(e) => handleOnChange(e)} />
          {pwdStatus === "text" ? <IoEyeOutline className="login-pwd-icon" onClick={togglePwd} /> : <IoEyeOffOutline className="login-pwd-icon" onClick={togglePwd} />}
        </div>
      </div>
      <div className="login-btn">
        <button className="button primary bg-btn-primary shadow" tabIndex="3" type="button" onClick={handleLogin}>
          {isLoading ? <SyncLoader color="white" size={8} /> : " Login"}
        </button>
      </div>
      <div className={error ? "msg error" : "msg success"}>{message}</div>
      <div className="login-signup">
        <p className="form-descp">
          Don't have an account?{" "}
          <Link 
            to="https://reworkacademy.co" 
            tabIndex="5">
            Academy
          </Link>
        </p>
      </div>
    </form>
  );
}
