import { useParams } from "react-router-dom";
import { Navigation } from "../../components/navigation/Navigation";
import "./style.css";
import { LiveClassTabDescription } from "./LiveClassTabDescription";
import { LiveClassTabObjective } from "./LiveClassTabObjective";
import { useState } from "react";

function LiveClassDetail() {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <Navigation title="Live Class" />
      <div className="live_class_details-container">
        <div className="liveClassDetails">
          <div className="live_class_banner">
            <div className="live_classbg"></div>
            <div className="live_class_banner-descr_top">
              <p>
                <strong>Date:</strong> Tue, 16th Jan 2024
              </p>
              <p>
                <strong>Time:</strong> 10:00 AM (CAT)
              </p>
              <p>
                <strong>Duration:</strong> 60min
              </p>
              <p>
                <strong>Class Size:</strong> 10
              </p>
              <button className="button white">Enroll for Class</button>
            </div>
            <div className="live_class_banner-descr_bottom">
              <p>
                <strong>Class Link:</strong> <a href="#">Click to Attend class</a>
              </p>
              <p>
                <strong>Instructor:</strong> James Peters
              </p>
            </div>
          </div>
        </div>
        <div className="live_class_tab">
          <div className="live_class_tab_links">
            <button onClick={() => setActiveTab(1)} className={``}>
              Description
            </button>
            {/* <button onClick={() => setActiveTab(2)} className={}>Objective</button> */}
            {/* <button>Instructor</button>
            <button>Class Recording</button> */}
          </div>
          <div className="live_class_ta_contents">
            <LiveClassTabDescription />
            <LiveClassTabObjective />
          </div>
        </div>
      </div>
    </>
  );
}
export default LiveClassDetail;
