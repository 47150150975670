import { useEffect, useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import "./style.css";
import { Link } from "react-router-dom";
import { IoDocumentTextOutline, IoPencil } from "react-icons/io5";

function Welcome({start_datetime}) {

  const [days, setDays] = useState(null);
  const [hrs, setHrs] = useState(null);
  const [mins, setMins] = useState(null);
  const [secs, setSecs] = useState(null);

  useEffect(() => {
    const countdown = () => {
      const start_date = new Date(start_datetime);
      const current_date = new Date();

      var diff = start_date - current_date;

      var day = Math.floor((diff / 3.6e6)/24);
      var hours =  Math.floor(diff / 3.6e6) - 24 * day;
      var minutes = Math.floor((diff % 3.6e6) / 6e4);
      var seconds = Math.floor((diff % 6e4) / 1000);

      setDays((prev) => (prev = day));
      setHrs((prev) => (prev = hours));
      setMins((prev) => (prev = minutes));
      setSecs((prev) => (prev = seconds));
    };

    const startInterval = setInterval(countdown, 1000);
    return () => clearInterval(startInterval);
  }, []);

  return (
    <>
      <div className="welcome">
        <div className="welcome_bg">
          <div className="welcome_content">
            <h4>Welcome to the Rework Academy Self-pace Student Portal</h4>
            <p>You applied for the</p>
            <h2 className="welcome_content_text">
              Full Stack Web Development with <strong>NODE JS</strong> course
            </h2>
            <p>Classes will begin in</p>
            <h2 className="welcome_content_countdown">
               {days} <span>days</span> :{hrs} <span>hrs</span> : {mins} <span>mins</span> : {secs} <span>secs</span>
            </h2>
          </div>
          <div className="welcome_content_bottom">
            <div className="welcome_content_bottom_item">
              <div className="Welcome_bottom_link">
                <h5>
                  <IoPencil />
                  Update Profile
                </h5>
                <p>While you wait for the coure to begin, kindly update your profile, by providing the necessary informations required.</p>
                <Link to="/profile">click to update</Link>
              </div>
            </div>
            {/* <div className="welcome_content_bottom_item">
              <div className="Welcome_bottom_link">
                <h5>
                  <IoDocumentTextOutline /> About Course
                </h5>
                <p>While you wait for the coure to begin, kindly update your profile, by providing the necessary informations required.</p>
                <Link>click to update</Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
