import EditHeader from "./EditHeader";
import { ProfileData } from "../../context/ProfileContext";

export default function () {
  const { name } = ProfileData();

  return (
    <div className="profile-name">
      <EditHeader heading={"Full Name"} />
      <div className="profile-name-text">
        <h4>{name}</h4>
        <p>This is the name that appears in your account and on your certificates.</p>
      </div>
    </div>
  );
}
