function ProgressBar({ value }) {
  return <div className="progress" role="bar" style={{ "--value": value }}></div>;
}

function CircularProgressBar({ value }) {
  return (
    <div className="progress" role="circular" style={{ "--value": value }}>
      <div></div>
    </div>
  );
}

export default function Progress() {
  return (
    <div>
      <ProgressBar value={50} />
      <div className="space"></div>
      <CircularProgressBar value={50} />
    </div>
  );
}

export function CircularProgress({ value }) {
  return (
    <div className="progress" role="circular" style={{ "--value": value }}>
      <div></div>
    </div>
  );
}

export function ProgressBarHorizontal({ val }) {
  return (
    <div>
      <ProgressBar value={val} />
      <div className="space"></div>
    </div>
  );
}
