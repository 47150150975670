import { IoBookOutline, IoCodeSharp, IoTimerOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export const Card = ({ title, descr, icon }) => {
  return (
    <div className="card__container flex space-between">
      <div className="card__left">
        <h4>{title}</h4>
        <h2>{descr}</h2>
      </div>
      <div className="card__right">
        <div className="card__icon">{icon}</div>
      </div>
    </div>
  );
};

export const Card2 = ({ img, title1, title2, children }) => {
  return (
    <div className="card2">
      <div className={`card2-title ${title2 ? "flex space-between" : ""}`}>
        <h4>{title1}</h4>
        <>{title2}</>
      </div>
      <div className={`card2_body ${img ? "flex" : ""}`}>
        {img ? <div className="card2__icon">{img}</div> : null}
        <div>{children}</div>
      </div>
    </div>
  );
};

export const ModuleCard = ({ mod }) => {
  return (
    <div className="module_card">
      <img src={mod.img} alt="Module" />
      <span className="module_status">{mod.status}</span>
      <div className="module_card_content">
        <h3 className="module__title">{mod.title}</h3>
        <div className="module_flexed flex">
          <IoBookOutline />
          <p>Topic: {mod.topicCount}</p>
        </div>
        <div className="module_flexed flex">
          <IoTimerOutline />
          <p>{mod.duration}</p>
        </div>
        <p className="module_card_subtitle">
          <span>Start Date:</span> {mod.start_date}
        </p>
        <p className="module_card_subtitle">
          <span>End Date:</span> {mod.end_date}
        </p>
      </div>
    </div>
  );
};

export const ProjectCard = ({ project }) => {
  return (
    <Link className="project_card" to={`/projects/${project.id}`}>
      <div className="p_card_header1 d-flex justify-content-between align-items-center">
        <div className={`p_card_icon ${project?.status.toLowerCase()}`}>
          <IoCodeSharp />
        </div>
        <span className={`${project?.status.toLowerCase()}`}>{project.status}</span>
      </div>
      <div className="p_card_header2">
        <h2>{project.title}</h2>
        <h5>({project.module})</h5>
        <p>Start Date: {project.start_date}</p>
        <p>End Date: {project.end_date}</p>
        <span className="p_card_header2_resp">{project.resp}</span>
      </div>
      <div className="p_card_content">
        <p className="p_card_content_descrp">{project.descrp}</p>
        <p className="d-flex justify-content-between">
          Task to do: <span>{project.task_count}</span>
        </p>
        <p className="d-flex justify-content-between">
          Task pending: <span>{project.task_pending}</span>
        </p>
        <p className="d-flex justify-content-between">
          Progress: <span>{project.progress}</span>
        </p>
      </div>
    </Link>
  );
};

export const PaymentsCard = ({ title, descrp }) => {
  return (
    <div className="payments_summary_card">
      <h5>{title}</h5>
      <h2>{descrp}</h2>
    </div>
  );
};
