import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";

function AttendanceInWeeks({attendance}) {


  return (
    <div className="attendance_in_weeks">
      <div className="attendance_in_weeks_header">
        <h5>Attendance Summary</h5>
      </div>
      <div className="attendance_in_weeks_contents">
        {attendance.map((attend) => (
          <div className="attendance_in_weeks_content" key={attend.id}>
            <h5>{attend.week}</h5>
            <div className="attendance_days">
              {attend.days.map((week, i) => (
                <div key={i} className="attendance_day">
                  <p>{week.day}</p>
                  {week.status === "present" ? (
                    <button className="attendance_day_present">
                      <IoCheckmarkSharp />
                    </button>
                  ) : (
                    <button className="attendance_day_absent">
                      <IoCloseSharp />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AttendanceInWeeks;
