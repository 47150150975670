import Quiz from './LessonQuiz';
import LessonVideo from './LessonVideo';
import Assignment from './LessonAssignment';

export default function Render({
  type, 
  data,
  mode,
  activeTab,
  setMode,
  setQuizEngine,
  setActiveTab,
  nextContent
}) {

    switch (type.toLowerCase()) {
      case 'video':
        return <LessonVideo 
                  nextContent={nextContent}
                  activeTab={activeTab} 
                  setActiveTab={setActiveTab} />
      case 'assignment':
        return <Assignment
                  data={data} 
                  activeTab={activeTab} />
      case 'project':
        return <Assignment
                  data={data} 
                  activeTab={activeTab} />
      case 'quiz':
        return <Quiz 
                  data={data} 
                  mode={mode} 
                  setMode={setMode} 
                  setQuizEngine={setQuizEngine} />
      default:
        return <></>
    }

  }
