import { useEffect, useState } from 'react';
import './quiz.css'

function Quiz({ 
  questions,
  setQuestions,
  setSummary,
  setMode }) {

  let count_down;
  let _summary;
  let timing;

  let [question, setQuestion] = useState(questions[0])
  let [index, setIndex] = useState(0);
  let [text, setText] = useState("Next");
  let elaspsed = 200
  let [time,setTime] = useState(elaspsed)

  let handleLeftClick = () => {
    index -= 1;
    if (index <= 0) index = 0;
    setQuestion({ ...questions[index] })
    setIndex(index)
    if(index < questions.length-1) setText("Next")
  }

  let handleRightClick = () => {

  
    if(text.toUpperCase() === "SUBMIT"){
      setMode('REVIEW')
      
    _summary = { answered: 0, correct: 0, incorrect: 0 }

      questions.map(e => {
        if(e.selected) _summary.answered++;
        e.options.map(k=> {
          if(k.selected && k.answer){
            _summary.correct++;
          }
        })
      })
  
      if(_summary.answered < questions.length)  _summary.answered =  questions.length;
      _summary.incorrect = _summary.answered - _summary.correct;

      setSummary({..._summary});

      return
    }
     
    
    index += 1;
   
    if (index >= questions.length) index -= 1;
    setQuestion({ ...questions[index] })
    setIndex(index)
    if(index === questions.length-1) setText("Submit")
    if(index === questions.length) setMode("REVIEW")
  
  }

  let handleSelected = (option)=>{

    let _questions = [...questions];

    let _question = _questions.find(x=> x.id === question.id);
    let _options = [..._question.options];

    _options.map(e => {
    
      e.selected = false
      if(e.id === option.id)  e.selected = true

      if(e.selected) _question.selected = true;
      
    });

   _question.options = _options;

    setQuestion({...question,..._question});
    setQuestions([..._questions]);
  
  }

  let getTime = (t)=>{
    let h="00";
    let m="00";
    let s="00";

    if(t <= 60){
      if(t <=9) s = "0"+t;
      else s = t;
    }
    else if(t <= 3600){
      let n = parseInt(t / 60)
      let a = n * 60
      let b = t -  a

      if(n <=9) m = "0"+n
      else m = n

      if(b <=9) s = "0"+b
      else s = b

    }

    return `${h}:${m}:${s}`
  }

  useEffect(()=>{

    count_down = setInterval(()=>{

      time -= 1;

      if(time <=0){
        clearInterval(count_down);

        _summary = { answered: 0, correct: 0, incorrect: 0 }

        questions.map(e => {
          if(e.selected) _summary.answered++;
          e.options.map(k=> {
            if(k.selected && k.answer){
              _summary.correct++;
            }
          })
        })
    
        if(_summary.answered === 0)  _summary.answered =  questions.length;
        _summary.incorrect = _summary.answered - _summary.correct;
  
        setSummary({..._summary});
        setMode('REVIEW')

      }
      
      timing = getTime(time);
      setTime(timing);

    },1000);

    return ()=> clearInterval(count_down);

  },[]);

  return <div className="qza-container">
    <div>

      <div className='flex space-between qza-botton-spacing'>
        <div>

          <span className='qza-question-no'>Question {index + 1}<span className='qza-no-title'>/{questions.length}</span>  </span>
        </div>
        <div className='flex space-between'>

          <div className='qza-button-spacing'>
            <svg width="20" height="20" fill="#56BFC7" ><path d="M18.016 3.932c.182.159.387.227.614.227a.907.907 0 00.614-1.59L18.062 1.5 16.88.432C16.516.09 15.926.114 15.607.5a.908.908 0 00.069 1.295l.5.455-.91 1.023a9.483 9.483 0 00-3.568-1.637 1.712 1.712 0 00-.363-.909C11.107.41 10.63.045 9.766 0h-.045c-.795 0-1.25.34-1.5.636-.273.319-.386.705-.432 1a9.263 9.263 0 00-7.34 9.069C.448 15.817 4.606 20 9.72 20s9.273-4.16 9.273-9.296c0-2.386-.91-4.568-2.387-6.227l.91-1.022.5.477zM9.721 18.159a7.459 7.459 0 01-7.455-7.454A7.459 7.459 0 019.721 3.25a7.459 7.459 0 017.454 7.454 7.459 7.459 0 01-7.454 7.455zm1.59-7.454c0 .863-.726 1.568-1.59 1.568a1.552 1.552 0 01-1.545-1.569c0-.522.227-1 .681-1.272V5.75c0-.5.41-.91.91-.91s.909.41.909.91v3.682c.454.273.636.75.636 1.272z"></path></svg>
          </div>
          <div>
            {time===elaspsed? "00:00:00":time}
          </div>
        </div>
      </div>
    </div>

    <div className="qza-question">
      {question.text}
    </div>
    <div className="qza-top">
      {
        question?.options.map((e, index) => {
         
          return <div  
          key={`id${index}`} 
          onClick={() => handleSelected(e)}
          className='qza-option qza-check'>
            <input
              onChange={() => handleSelected(e)}
              checked={e.selected}
              type="radio"
              name="selected"
              className="qza-checkbox" />
            <label>{e.text}</label>
         
          </div>
        })
      }

    </div>
  
    <div className="m-top">
     {
        index <=0 ? "" : <button className="qza-button primary" onClick={handleLeftClick}>Back</button>
      }

      <button 
      className="qza-button primary" 
      onClick={handleRightClick}>
        {text}
      </button>
     
    </div>
  </div>
}

function QuizReview({
  summary,
  questions,
  handleContinueCourse }) {

  let [question, setQuestion] = useState(questions[0])
  let [index, setIndex] = useState(0);

  let handleLeftClick = () => {
    index -= 1;
    if (index <= 0) index = 0;
    setQuestion({ ...questions[index] });
    setIndex(index);
  }

  let handleRightClick = () => {
    index += 1;
    if (index >= questions.length) index -= 1;
    setQuestion({ ...questions[index] });
    setIndex(index);
  }

  // setActiveAccordion(activeTab?.chapter_id)
  // setActiveLesson(activeTab?.id)

  return <div className="qza-container">
    <div>
      <div className="qza-review">Review answers</div>
      <div className='flex space-between qza-botton-spacing'>
        <div>

          <span className='qza-question-no'>Question {index + 1}<span className='qza-no-title'>/{questions.length}</span>  </span>
          <span className='qza-correct-no'>{summary.correct} correct</span>
          <span className='qza-incorrect-no'>{summary.incorrect} incorrect</span>
        </div>
        <div className='flex space-between'>

          <div
            onClick={index === 0 ? () => { } : handleLeftClick}
            className={index === 0 ? "qza-button-prev qza-button-spacing qza-buttion-inactive" : "qza-button-prev qza-button-spacing"}>
            <svg viewBox="0 0 256 512" height="18" transform="rotate(180)" className="left-arrow"><path fill="currentColor" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
          </div>
          <div
            onClick={index === questions.length - 1 ? () => { } : handleRightClick}
            className={index === questions.length - 1 ? "qza-button-next qza-buttion-inactive" : "qza-button-next"}>
            <svg viewBox="0 0 256 512" height="18" className="right-arrow"><path fill="currentColor" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
          </div>
        </div>
      </div>
    </div>

    <div className="qza-question">
      {question.text}
    </div>
    <div className="qza-top">
      {
        question?.options.map((e, index) => {
          let class_name = ""
          if (e.answer && e.selected) {
            class_name = "qza-option qza-correct"
          }
          else if (e.selected) {
            class_name = "qza-option qza-incorrect"
          }
          else {
            class_name = "qza-option qza-unselect"
          }

          return <div 
          key={`id${index}`}
          className={class_name}>
            <input
              type="checkbox"
              id={`id${index}`}
              className="qza-checkbox" />
            <label htmlFor={`id${index}`}>{e.text}</label>
          </div>
        })
      }

    </div>

    <div>
    </div>
    <div className="m-top">
      <button className="qza-button primary" onClick={handleContinueCourse}>Continue course</button>
      <button className="qza-button secondary">Retake quiz</button>
    </div>
  </div>
}

function QuizEngine({
  mode,
  nextTab,
  setMode,
  setActiveTab,
  setQuizEngine}) {


  let initial_questions = [
    {
      id: "1",
      selected:false,
      text: "The high school principal asked you to conduct a survey on student satisfaction in the entire high school. You go and ask all your classmates about their opinion. Then you present the results to the principal. Was this the population or a sample drawn from it? How is the value that you presented called?",
      explanation: "You interviewed only one class – your own. This is a sample taken from the population of all students studying in the high school. Since it was a sample, the value associated with it was a statistic.",
      options: [
        {
          id: "1",
          text: "population, statistics",
          selected: false,
          answer: false
        },
        {
          id: "2",
          text: "population, parameter",
          selected: false,
          answer: false
        },
        {
          id: "3",
          text: "sample, parameter",
          selected: false,
          answer: true
        },
        {
          id: "4",
          text: "sample, statistics",
          selected: false,
          answer: false
        }
      ]
    },
    {
      id: "2",
      selected:false,
      text: "You are trying to estimate the average valuation (worth) of start-ups in the USA. You go to Silicon Valley and visit 200 start-ups in a random manner. What is a possible problem of your study?",
      explanation: " You were estimating a statistic for all start-ups in the USA. However, your sample was taken only from startups in Silicon Valley. While this may be a proxy for California, it does not represent the other 49 states. Therefore, the sample is not representative.",
      options: [
        {
          id: "8",
          text: "The sample is too small",
          selected: false,
          answer: false
        },
        {
          id: "5",
          text: "The sample is not representative",
          selected: false,
          answer: true
        },
        {
          id: "6",
          text: "The population is unknown",
          selected: false,
          answer: false
        },
        {
          id: "7",
          text: "The sample is not random",
          selected: false,
          answer: false
        }
      ]
    },
    {
      id: "3",
      selected:false,
      text: "What is the full meaning of CSS?",
      explanation: "Right from the first version it has always been determined as such",
      options: [
        {
          id: "12",
          text: "Cascading System Sheet",
          selected: false,
          answer: false
        },
        {
          id: "11",
          text: "Cascading style sheet",
          selected: false,
          answer: true
        },
        {
          id: "10",
          text: "Cease Synthetic System",
          selected: false,
          answer: false
        },
        {
          id: "9",
          text: "Conceptual Scopic Sythesis",
          selected: false,
          answer: false
        }
      ]
    }
  ]

  let initial_summary = {
    answered: 0,
    correct: 0,
    incorrect: 0
  }

  let [questions, setQuestions] = useState(initial_questions);
  let [summary, setSummary] = useState(initial_summary)

  let handleContinueCourse = () => {
    setQuizEngine(false)
    setMode("QUIZ");
    setActiveTab(nextTab)
  }

  return (mode === "REVIEW" ?
    <QuizReview
      summary={summary}
      handleContinueCourse={handleContinueCourse}
      questions={questions} /> :
    <Quiz
      summary={summary}
      questions={questions}
      setSummary={setSummary}
      setQuestions={setQuestions}
      setMode={setMode}
    />)
}


export default QuizEngine;