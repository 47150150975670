import { useState, useEffect } from "react";
import EditHeader from "./EditHeader";
import editIcon from "../../assets/img/edit.svg";
import facebook from "../../assets/img/facebook.svg";
import instagram from "../../assets/img/instagram.svg";
import linkedin from "../../assets/img/linkedin.svg";
import twitter from "../../assets/img/twitter.svg";
import Modal from "../../components/profile/Modal";
import { ProfileData } from "../../context/ProfileContext";
import ProfileService from "./ProfileService";

export default function ProfileSocialMedia({user_id}) {

  const { socialMedia,setSocialMedia } = ProfileData();
  const [isOpen, setIsOpen] = useState(false);

  const handleSocialMedia = ()=>{
    let data ={ social_links:socialMedia }
    
    ProfileService.updateProfile(user_id,data)
    .then(e=>{

      if(e.type === "SUCCESS"){
        setIsOpen(false)
      }

    });

  }

  useEffect(() => {

    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

  }, [isOpen]);

  return (
    <div className="profile-social-media">
      <EditHeader heading={"Social Links"}>
        <button className="icon" onClick={() => setIsOpen(true)}>
          <img src={editIcon} alt="Edit" />
          <span>Edit</span>
        </button>
      </EditHeader>
      <div className="profile-social-texts">
        <div className="social-link">
          {socialMedia.twitter ? (
            <div className="social-media-sub-link">
              <img src={twitter} alt="Twitter" />
              <span>{ socialMedia.twitter }</span>
            </div>
          ) : (
            <button className="social-link-edit">
              <img src={editIcon} alt="Edit" />
              <span>Add Twitter</span>
            </button>
          )}
        </div>
        <div className="social-link">
          {socialMedia.facebook ? (
            <div className="social-media-sub-link">
              <img src={facebook} alt="Facebook" />
              <span>{socialMedia.facebook}</span>
            </div>
          ) : (
            <button className="social-link-edit">
              <img src={editIcon} alt="Edit" />
              <span>Add Facebook</span>
            </button>
          )}
        </div>
        <div className="social-link">
          {socialMedia.instagram ? (
            <div className="social-media-sub-link">
              <img src={instagram} alt="Instagram" />
              <span>{socialMedia.instagram}</span>
            </div>
          ) : (
            <button className="social-link-edit">
              <img src={editIcon} alt="Edit" />
              <span>Add Instagram</span>
            </button>
          )}
        </div>
        <div className="social-link">
          {socialMedia.linkedin ? (
            <div className="social-media-sub-link">
              <img src={linkedin} alt="LinkedIn" />
              <span>{socialMedia.linkedin}</span>
            </div>
          ) : (
            <button className="social-link-edit">
              <img src={editIcon} alt="Edit" />
              <span>Add LinkendIn</span>
            </button>
          )}
        </div>
      </div>
      {isOpen && (
        <Modal setIsOpen={setIsOpen} title={"Social Links"} onClick={handleSocialMedia}>
          <div className="modal--sub-heading">
            <p>Add social media names, usernames/handles or URLs.</p>
          </div>
          <form action="" className="form profile--form">
            <div className="form-group">
              <label htmlFor="twiiter">Twiiter</label>
              <input type="text" placeholder="@johnwick" value={socialMedia.twitter} name="twitter" onChange={ (e)=> setSocialMedia({...socialMedia,[e.target.name]:e.target.value})} />
            </div>
            <div className="form-group">
              <label htmlFor="facebook">Facebook</label>
              <input type="text" placeholder="John Wick" value={socialMedia.facebook} name="facebook" onChange={ (e)=> setSocialMedia({...socialMedia,[e.target.name]:e.target.value})}/>
            </div>
            <div className="form-group">
              <label htmlFor="instagram">Instagram</label>
              <input type="text" placeholder="@johnwick" value={socialMedia.instagram} name="instagram" onChange={ (e)=> setSocialMedia({...socialMedia,[e.target.name]:e.target.value})}/>
            </div>
            <div className="form-group">
              <label htmlFor="linkedin">LinkedIn</label>
              <input type="text" placeholder="John Wick" value={socialMedia.linkedin} name="linkedin" onChange={ (e)=> setSocialMedia({...socialMedia,[e.target.name]:e.target.value})}/>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );

}
