import { useState, useEffect } from "react";
import EditHeader from "./EditHeader";
import editIcon from "../../assets/img/pencil.svg";
import Modal from "../../components/profile/Modal";
import { ProfileData } from "../../context/ProfileContext";
import ProfileService from "./ProfileService";

export default function ProfileLocation({user_id,profile,setProfile}) {
  const { location,setLocation } = ProfileData();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickLocation = ()=>{
    let data ={
      country:profile?.country,
      state:profile?.state,
      city:profile?.city,
      landmark:profile?.landmark,
      address:profile?.address
    }

    // console.log(data)
    
    ProfileService.updateProfile(user_id,data)
    .then(e=>{

      if(e.type === "SUCCESS"){
        setIsOpen(false)
        setLocation(data.country+","+data.state)
      }

    });

  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  return (
    <div className="profile-location">
      <EditHeader heading={"Location"}>
        <button className="icon" onClick={() => setIsOpen(true)}>
          <img src={editIcon} alt="Edit" />
          <span>Edit</span>
        </button>
      </EditHeader>
      <div className="profile-location-text">
        <h4>{location}</h4>
      </div>
      {isOpen && (
        <Modal setIsOpen={setIsOpen} title={"Edit Location"} onClick={handleClickLocation}>
          <div className="modal--sub-heading">
            <p>Your location makes it easier for job offers and recommendations</p>
          </div>
          <form action="" className="form profile--form ">
            <div className="form--grid">
              <div className="form-group">
                <label>Country</label>
                <input type="text" value={profile.country} name="country" onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} />
              </div>
              <div className="form-group">
                <label>State</label>
                <input type="text" value={profile.state} name="state" onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} />
              </div>
              <div className="form-group">
                <label>City</label>
                <input type="text" value={profile.city} name="city" onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} />
              </div>
              <div className="form-group">
                <label>Landmark</label>
                <input type="text" value={profile.landmark} name="landmark" onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} />
              </div>
            </div>
            <div className="form-group">
              <label>Address</label>
              <textarea  value={profile.address} onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} name="address"></textarea>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
