import {
  IoLibraryOutline,
  IoCodeSlashOutline,
  IoDesktopSharp,
  IoPeopleOutline,
  IoPlayCircleOutline,
  IoNotificationsOutline,
  IoBriefcaseOutline,
  IoSettingsOutline,
  IoPersonOutline,
  IoBookOutline,
  IoWalletOutline,
} from "react-icons/io5";


export const entry_sidebar = [
  // { id: 1, icon: <IoCodeSlashOutline />, title: "Projects", link: "/projects",show:true }
];

export const student_sidebar = [
  { id: 1, icon: <IoLibraryOutline />, title: "Dashboard", link: "/dashboard" },
  { id: 2, icon: <IoBookOutline />, title: "Modules", link: "/modules" },
  { id: 4, icon: <IoWalletOutline />, title: "Payments", link: "/payments" },
  { id: 5, icon: <IoPeopleOutline />, title: "Attendance", link: "/attendance" },
  { id: 6, icon: <IoNotificationsOutline />, title: "Notifications", link: "/notifications" },
  { id: 7, icon: <IoBriefcaseOutline />, title: "Projects", link: "/projects" },
  { id: 8, icon: <IoSettingsOutline />, title: "Settings", link: "/settings" },
  { id: 9, icon: <IoPersonOutline />, title: "Profile", link: "/profile" },
  { id: 10, icon: <IoCodeSlashOutline />, title: "Peer Pairings", link: "/peer-pairings" },

];

export const self_pace_sidebar = [
  // { id: 0, icon: <IoPlayCircleOutline />, title: "Assessments", link: "/assessments" },
  { id: 1, icon: <IoLibraryOutline />, title: "Dashboard", link: "/dashboard",show:true },
  { id: 2, icon: <IoDesktopSharp />, title: "Lessons", link: "/lessons" ,show:false},
  { id: 3, icon: <IoDesktopSharp />, title: "Live Classess", link: "/live_classes",show:false },
  { id: 3, icon: <IoDesktopSharp />, title: "Study Group", link: "/study_group",show:false },
  { id: 4, icon: <IoCodeSlashOutline />, title: "Projects", link: "/projects",show:false },
  { id: 5, icon: <IoCodeSlashOutline />, title: "Assignment", link: "/assignments" ,show:false},
  // { id: 5, icon: <IoCodeSlashOutline />, title: "Peer Pairings", link: "/peer-pairings",show:false },
  { id: 6, icon: <IoPeopleOutline />, title: "Attendance", link: "/attendance",show:false },
  { id: 7, icon: <IoNotificationsOutline />, title: "Notifications", link: "/notifications",show:false },
  { id: 8, icon: <IoWalletOutline />, title: "Payments", link: "/payments",show:false },
  { id: 9, icon: <IoSettingsOutline />, title: "Settings", link: "/settings" ,show:true},
  { id: 10, icon: <IoPersonOutline />, title: "Profile", link: "/profile",show:true },
];