import { Navigation } from "../../components/navigation/Navigation";
import "./style.css";
import ProjectDetails from "./ProjectDetails";
import ProjectSubmission from "./ProjectSubmission";
import AssessmentService from "./AssessmentService";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalData } from "../../context/GlobalContext";
import { IoPersonOutline } from "react-icons/io5";
import { PageRedirect, Route,PageTitle } from "react-paget";

function SingleProject() {

  let { id } = useParams();
  let [assessment, setAssessment] = useState({
    status: "",
    duration: "",
    start_date: "",
    end_date: "",
    title: "",
    descp: ''
  });

  let [phases, setPhase] = useState([{
    id: 1,
    title: "Phase 1",
    submission_date: "19/08/2023",
    tasks: [
      { id: 1, descrp: "Design Home Page", status: "Not Approved" },
      { id: 2, descrp: "Design About Page", status: "Pending" },
      { id: 3, descrp: "Design Contact Page", status: "Pending" },
    ],
  },]);
  
  let [submissions,setSubmission] = useState([
    { name: "Phase 1",file_name:"Jquery Project", submitted_date: new Date().toLocaleString() },
   
  ]);

  let [forums,setForum] = useState([]);

  let { store } = GlobalData();
  let detail = store?.DETAIL;
  let user_id = detail?.id || "x9lmm7wln4fszxo";

  let handleReview = (text)=>{

    let data = {assessment_id:id,sender_id:user_id,text }

    AssessmentService.createReview(data)
    .then((e)=>{

      let data  = {
          id: e.data?.id,
          name: e.data?.user.name,
          img: <IoPersonOutline />,
          time:e.data?.epoch,
          descrp:e.data?.text,
          type:e.data?.type
        
      }
      setForum([...forums,...[data]])
    })

  }

  let getReview = (user_id,id) => {

    AssessmentService.getReview(user_id, id)
    .then(e => {

      let arr = e?.map(x => ({
        id: x.id,
        name: x.user?.name,
        img: <IoPersonOutline />,
        time:x.epoch,
        descrp:x.text,
        type:x.type
      }));

      setForum([...forums,...arr ]);
    });
  }

  useEffect(() => {

    AssessmentService.getAssessment(user_id, id)
    .then(e => {

      let arr = e?.phases.map(x => ({
        id: x.id,
        title: x.name,
        submission_date: x.end_date,
        tasks: x.tasks.map(k => ({
          id: k.id,
          descrp: k.task,
          status: k.status,
        }))

      }));

      let submitted_arr = e?.phases.map(x => (
        x.submissions.map(k => ({
          name: k.name,
          file_name: k.file_name,
          submitted_date: k.submitted_date,
        }))
      )).flat();

      setAssessment({ ...assessment, ...e });
      setPhase(arr);
      setSubmission([...submitted_arr]);

    });

    getReview(user_id,id);

  }, []);

  return (
    <PageTitle title="Portal-Project Review">
      <Navigation title={"Single Project"} />
      <div className="db__height">
        <div className="single_project_container">

          <ProjectDetails 
            assessment={assessment} 
            phases={phases} 
            forums={forums} 
            handleReview={handleReview}/>

          <ProjectSubmission 
            user_id={user_id} 
            assessment={assessment}
            submissions={submissions} 
            setSubmission={setSubmission} />
        </div>
      </div>
    </PageTitle>
  );
}

export default SingleProject;
