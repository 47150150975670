
import { Link } from "react-router-dom";
import {
  IoLockClosedOutline
} from "react-icons/io5";

function LessonLists({ lessons, current }) {

  return (
    <div>
      {lessons.length ? (
        <div className="lessons_grid">

          {

            lessons.map((lesson) => {

              if (!lesson.is_locked) {
                return <Link
                  to={`${current.url}?id=${lesson.id}`}
                  className="lessons_content"
                  key={lesson.id}>
                  <img
                    crossOrigin="anonymous"
                    src={lesson.icon}
                    alt={lesson.name} />
                  <h4>{lesson.name}</h4>
                  {/* <span>{lesson.lectures} Lectures</span> */}

                </Link>
              }
              return <div
                className="lessons_content"
                key={lesson.id}>
                <img
                  crossOrigin="anonymous"
                  src={lesson.icon}
                  alt={lesson.name} />
                <h4>{lesson.name}</h4>
                {/* <span>{lesson.lectures} Lectures</span> */}
                <div className="flex-reverse">
                  <IoLockClosedOutline />
                </div>

              </div>
            })}
        </div>
      ) : (
        <h3>No Lesson Available</h3>
      )}
    </div>
  );
}

export default LessonLists;
