import { useState, useEffect } from 'react';
import { IoCheckmarkCircle, IoEllipseOutline } from "react-icons/io5";
import { Card2 } from "../../components/cards/Card";
import { Navigation } from "../../components/navigation/Navigation";
import { CircularProgress } from "../../templates/progress/Progress";
import { DashboardCards } from "./DashboardCards";
import { GlobalData } from "../../context/GlobalContext";
import { PageTitle } from "react-paget";
import { useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";

import Img from '../../assets/img/full-stack-pic.jpg'
import DashboardService from './DashboardService';
import LoginPayment from "../LoginPayment/LoginPayment";
import Welcome from '../welcomePage/Welcome';
import Loading from '../../components/Loading'
import PaymentService from '../Payment/PaymentService';
import Success from '../../assets/svg/success.svg'
import Error from '../../assets/svg/error.svg'
import Pending from '../../assets/svg/pending.svg'

import "./style.css";



function PaymentAction({ trans, tx_ref }) {
  
  console.log(trans)

  if (trans && trans.status === "approved"){
    window.location.href = "/dashboard"
  }

  return <>
    {
      !trans ? <Loading /> : <div className="bg-blue-600 text-white min-h-screen p-10">
        <div className="max-w-sm mx-auto bg-white">
        
          <div
            className="flex justify-center my-8 mx-auto check-wrap"
            style={{ maxwidth: "15rem", marginTop: "5em", marginBottom: "5em" }}>
            {
              trans.status === "approved" ? <img alt="Success SVG Vector Icon" fetchpriority="high" width="150" height="150" decoding="async" data-nimg="1" style={{ color: "transparent", width: "100px", height: "100px" }} src={Success} /> :
                trans.status === "pending" ? <img alt="Success SVG Vector Icon" fetchpriority="high" width="150" height="150" decoding="async" data-nimg="1" style={{ color: "transparent", width: "100px", height: "100px" }} src={Pending} /> :
                  <img alt="Success SVG Vector Icon" fetchpriority="high" width="150" height="150" decoding="async" data-nimg="1" style={{ color: "transparent", width: "100px", height: "100px" }} src={Error} />}
          </div>

          <h2 className="text-center text-3xl">Payment {trans.status}</h2>
          <div className="text-center text-2xl font-bold">
            {trans.currency === "NGN" ? "₦" : "$"}{Number(trans.amount).toLocaleString()}
          </div>

          <div className="text-center bg-blue-500 rounded-lg p-8 flex flex-col space-y-1 my-8" style={{ color: "#fff" }}>
            <div>Your payment {status === "APPROVED" ? "was successfully sent to" : "failed to send to"}</div>
            <div className="font-bold underline cursor-pointer hover:text-blue-100">Rework Academy</div>
            <div>{new Date(trans.created_at).toLocaleString()}</div>
            <div>
              Payment ID:
              <span className="font-bold underline cursor-pointer hover:text-blue-100">{tx_ref}</span>
            </div>
          </div>

        </div>
      </div>
    }
  </>
}

function Dashboard() {

  let init_dash = {
    course_fee: 0,
    amount_paid: 0,
    balance: 0,
    attendance: {
      progress: 0
    },
    course: {

    }
  }


  let init_checklist = {
    check_list_progress: 0,
    checklists: [],
  }

  let [searchParams] = useSearchParams()

  let tx_ref = searchParams.get("reference");

  let { store } = GlobalData();
  let NOTIFICATION_SIZE = 5;


  let [info, setInfo] = useState(init_dash);
  let [list, setList] = useState(init_checklist);
  let [notifications,setNotification] = useState([])
  let [progress,setCourseProgress] = useState({})
  let [title, setTitle] = useState("")
  let [page_load, setPageLoad] = useState(true);
  let [trans, setTrans] = useState()



  useEffect(() => {

    let user_id = store?.DETAIL?.id;
    let course_id = store?.DETAIL?.course.id;

    let interval = setInterval(() => {


      if (tx_ref) {

        PaymentService
          .getPaymentByTransactionId(tx_ref)
          .then(res => {

            let status = res.status.toLowerCase();
            let first = status[0].toLowerCase();
            let result = first + status.substring(1)
            res.status = result;

            setTrans(res)
            if(result == "approved"){
              store['DETAIL'] 
              Trove.resave(store);
            }

          })

      }

    }, 5000)


    if (user_id) {

      DashboardService.info(user_id)
      .then(e => {

        if (e.type != "INVALID_ID") {
          let _student = { ...init_dash, ...e }
       
          let _date1 = new Date(store.DETAIL.course_started);
          let _date2 = new Date();
          let _started = _date1 >= _date2;
    
          setInfo(_student);
          setPageLoad(false)

          if (_student.info.has_made_payment && !_started) setTitle("Dashboard");
          else if (tx_ref) setTitle("PaymentStatus");
          else if (_student.info.has_made_payment && _started) setTitle("Welcome");
          else setTitle("Payment");

        }

      });

      DashboardService.checklist(user_id)
      .then(e => {
        if (e.type != "INVALID_ID") setList({
          ...init_checklist,
          ["checklists"]: e.data,
          ["checklist_progress"]: e.checklist_progress
        });

      });

      DashboardService.getNotification(user_id,NOTIFICATION_SIZE)
      .then(e =>setNotification(e));

      DashboardService.getCourseProgress(user_id,course_id)
      .then(e =>{
        setCourseProgress(e)
      });


    }

    return () => clearInterval(interval);

  }, [store]);


  return (

    <>
      <PageTitle title="Portal-Dashboard" >
        <Navigation title={title} />
        {
          page_load ? <Loading /> :
            title === "PaymentStatus" ? <PaymentAction trans={trans} tx_ref={tx_ref} /> :
              title === "Payment" ? <LoginPayment profile={store?.DETAIL} /> :
                title === "Welcome" ? <Welcome start_datetime={store?.DETAIL?.course_started} /> :

                  <div className="space-top dashboard__container">
                    <DashboardCards data={info} />
                    <div className="dashboard_body">
                      <div className="dashboard_body_left">
                        <Card2 title1="Getting Started Check List">
                          <div className="flex db__left1">
                            <div>
                              <CircularProgress value={list.checklist_progress} />
                            </div>
                            <div className="db__left1-sub">
                              {
                                list.checklists.map(e => {
                                  return <p key={e.id}>
                                    {e.completed ? <IoCheckmarkCircle /> : <IoEllipseOutline />}
                                    {e.task}
                                  </p>
                                })
                              }
                            </div>
                          </div>
                        </Card2>
                        <Card2 
                          title1="Current Module" 
                          
                          title2={<Link to={`${progress?.lesson?.url}?id=${progress?.module?.id}`} style={{color:"#fff"}}
                          className='card-continue'>
                          Continue Learning
                        </Link>}>
                      <h3 className="db__card_title">{progress?.lesson?.title}</h3>
                      <p className="db__card_subtitle">
                        <span>Module: </span> {progress?.module?.name}
                      </p>
                      <p className="db__card_descr">
                       {progress?.lesson?.descp}
                      </p>
                      {/* <div className="db__left1-sub">
                        <div className="flex space-between">
                          <p>
                            <IoCheckmarkCircle /> Assignment 1
                          </p>
                          <button className="btn btn-success">Completed</button>
                        </div>
                        <div className="flex space-between">
                          <p>
                            <IoCheckmarkCircle className="text-warning" /> Assignment 2
                          </p>
                          <button className="btn btn-warning">Ongoing</button>
                        </div>
                        <div className="flex space-between">
                          <p>
                            <IoEllipseOutline /> Project
                          </p>
                          <button className="btn btn-secondary">Pending</button>
                        </div>
                      </div> */}
                    </Card2>
                        <Card2 title1="Notification" title2={<Link to="/notifications">View all notifications</Link>}>
                          {
                            notifications.map(e => {
                              return <div className="db_notifications" key={e.id}>
                                <div className="db__nofi_header flex space-between">
                                  <h5>{e.caption}</h5>
                                  <h6>{e.created_at}</h6>
                                </div>
                                <p>{e.descp}</p>
                              </div>
                            })
                          }
                        </Card2>
                      </div>
                      <div className="dashboard_body_right">
                        <Card2 
                          title1='Course Details' 
                          title2={<Link to='/'>View Details</Link>} 
                          img={<img src={Img} alt="Course" />} >
                          <div className="db_course_card">
                            <h2>{info.course.name}</h2>
                            <div className="flex space-between">
                              <p><span>Start Date:</span> {info.course.start_date}</p>
                              <p>-</p>
                              <p><span>End Date:</span> {info.course.estimated_end_date}</p>
                            </div>
                              <p><span>Duration</span>:{info.course.duration}</p>
                          </div>
                          <div className="db_courseprogress">
                          
                            <div className="progress" role="bar" value='1'></div>
                          </div>
                        </Card2>
                        {/* <Card2 title1='Upcoming Module' title2={<Link to='/'>View Details</Link>} img={<img src={Img} alt="Course" />} >
                      <div className="db_course_card">
                        <h2>Introduction to JavaScript</h2>
                        <div className="d-sm-flex space-between">
                          <p><span>Start Date:</span> July 10 2023</p>
                          <p>-</p>
                          <p><span>End Date:</span> April 10th 2023</p>
                        </div>
                      </div>
                      <div className="db_module_card">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo non voluptatibus nemo sunt? Omnis nesciunt ipsam mollitia, repellendus quod placeat.</p>
                      </div>
                    </Card2> */}
                        <Card2 title1='Payment' title2={<Link to='/'>View Details</Link>}>
                          <div className="db_payment_card">
                            <h5>Next payment due date</h5>
                            <h3>{new Date(info.payment_due_date).toDateString()}</h3>
                            {/* <h5>Remaining Months</h5>
                            <h3>6 Months</h3> */}
                          </div>
                        </Card2>
                      </div>
                    </div>
                  </div>


        }
      </PageTitle>
    </>
  );
}

export default Dashboard;
