import axios from 'axios';
import config from "../config"

class ProfileService{

    getProfile(student_id){
        let url = (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id/profile").bind(student_id);
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getProject(student_id){
        let type = "projects"
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_user_id/assessments").bind(student_id).query({type});
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getLanguages(){
    
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/languages");
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getDegree(){
    
      let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/degree");
      return new Promise((resolve,reject) => {
       axios.get(url)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
  }

    updateProfile(student_id,data){
        let url = (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id").bind(student_id);
        return new Promise((resolve,reject) => {
         axios.put(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    addCert(data){
      let url = (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/certificates");
      return new Promise((resolve,reject) => {
       axios.post(url,data)
       .then(res => {
          resolve(res.data);
       })
       .catch(err => reject(err.message));
     })
  }

     uploadCert(user_id,cert_id,data){

      let formData = new FormData();
      let url = (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id/certificates/:cert_id").bind(user_id,cert_id);
      
      formData.append("certificate", data);


      return fetch(url, {
          method: "PUT",
          body: formData,
      }).then((res) => res.json());
     
  };

}

export default new ProfileService;