import { useState, useEffect } from "react";
import EditHeader from "./EditHeader";
import editIcon from "../../assets/img/pencil.svg";
import Modal from "../../components/profile/Modal";
import { ProfileData } from "../../context/ProfileContext";
import ProfileService from "./ProfileService";

export default function ProfileEducation({ degrees, user_id }) {

  const { education } = ProfileData();
  const [isOpen, setIsOpen] = useState(false);

  const [cert_file, setCertFile] = useState("");
  const [cert, setCert] = useState({
    user_id,
    "school": "",
    "course": "",
    "degree": "",
    "start_month": "",
    "end_month": "",
    "start_year": "",
    "end_year": ""
  });

  
  const handleClickUpload = () => {

    // ProfileService.addCert(cert)
    //   .then(e => {
    let e = {
      data: {
        id: "x91disolnj1u03n"
      }
    }
    console.log(e)
    // if (e.type === "SUCCESS") {

    ProfileService.uploadCert(user_id, e.data.id, cert_file)
    .then(e => {

        if (e.type === "SUCCESS") {
          setIsOpen(false)
        }

    });
    // }

    // });

  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  return (
    <div className="profile-education">
      <EditHeader heading={"Education / Certificate"}>
        <button className="icon" onClick={() => setIsOpen(true)}>
          <img src={editIcon} alt="Edit" />
          <span>Edit</span>
        </button>
      </EditHeader>
      <div className="profile-education-text">
        <h4>{education}</h4>
      </div>
      {isOpen && (
        <Modal setIsOpen={setIsOpen} title={"Certificate"} onClick={handleClickUpload}>
          <form className="form profile--form">
            <div className="form-group">
              <label htmlFor="school">School</label>
              <input type="text" value={cert.school} onChange={(e) => setCert({ ...cert, ["school"]: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="school">Course of Study</label>
              <input type="text" placeholder="Computer science, Geography" value={cert.course} onChange={(e) => setCert({ ...cert, ["course"]: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="school">Degree</label>
              <select value={cert.degree} onChange={(e) => setCert({ ...cert, ["degree"]: e.target.value })}>
                <option value="">Select Degree</option>
                {
                  degrees?.map(x => <option key={x.id} value={x.name}>{x.name}</option>)
                }

              </select>
            </div>
            <div className="form--grid">
              <div className="form-group">
                <label htmlFor="Start date">Start Month</label>
                <select value={cert.start_month} onChange={(e) => setCert({ ...cert, ["start_month"]: e.target.value })}>
                  <option value="">Select</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="Start date">Start year</label>
                <select value={cert.start_year} onChange={(e) => setCert({ ...cert, ["start_year"]: e.target.value })}>
                  <option value="">Select</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                  <option value="2014">2014</option>
                  <option value="2013">2013</option>
                  <option value="2012">2012</option>
                  <option value="2011">2011</option>
                  <option value="2010">2010</option>
                  <option value="2009">2009</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="Start date">End Month / Expected</label>
                <select value={cert.end_month} onChange={(e) => setCert({ ...cert, ["end_month"]: e.target.value })}>
                  <option value="">Select</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="Start date">End year / Expected</label>
                <select value={cert.end_year} onChange={(e) => setCert({ ...cert, ["end_year"]: e.target.value })}>
                  <option value="">Select</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                  <option value="2014">2014</option>
                  <option value="2013">2013</option>
                  <option value="2012">2012</option>
                  <option value="2011">2011</option>
                  <option value="2010">2010</option>
                  <option value="2009">2009</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="">Upload highest level of degree</label>
              <div className="form--certificate">
                <input type="file" id="upload_cert" onChange={(e) => setCertFile(e.target.files[0])} />
                <label htmlFor="upload_cert">+ Upload</label>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
