import { useState, useEffect } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import LessonLists from "./LessonLists";
import html_img from "../../assets/img/html_img.png";
import css_img from "../../assets/img/css_img.png";
import "./style.css";
import { GlobalData } from "../../context/GlobalContext";
import LessonService from "./LessonService";
import { PageTitle } from "react-paget";
import Loading from '../../components/Loading'


function Lessons() {

  const { store } = GlobalData();

  const [lessons, setLessons] = useState([
    {
      id: 1,
      name: "HTML & CSS",
      lectures: 0,
      icon: html_img
    }
  ]);
  let [page_load, setPageLoad] = useState(true);
  let [current, setCurrent] = useState({
    id: "123",
    url: "/lessons/html-and-css/introduction-to-html-tags-quiz",
    description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
    duration: 10,
    type: "quiz",
    quiz_id: "123",
    completed: true,
    current: false,
    locked: false,
  })
  let completed = []

  useEffect(() => {

    if (store) {
      const course_id = store?.DETAIL?.course?.id;
      const user_id = store?.DETAIL?.id;

      if (course_id) {

        LessonService
          .getModules(user_id,course_id)
          .then(res => {

            setPageLoad(false);
            let data = []

            res.map(e =>{
              data.push({
                id:e.id,
                name:e.name,
                lectures:e.lessons,
                icon:e.icon,
                is_locked:e.is_locked,
                is_completed:e.is_completed,
                is_current:e.is_current,
                is_started:e.is_started
              })
            });

            setLessons(data);

          });

        LessonService
          .getCourseProgress(user_id,course_id)
          .then(res => {

            setCurrent({
                id: res?.lesson?.id,
                url: res?.lesson?.url,
                description:res?.lesson?.descp,
                duration: res?.lesson?.resource_duration,
                type: res?.lesson?.type?.toLowerCase(),
                quiz_id: res?.lesson?.resource_id,
                completed: res?.lesson?.completed,
                current: true,
                locked: false,
              
            })

          });
      }

    }

  }, [store]);

  return (
    <PageTitle title="Portal - Lesson" >
      <Navigation title="Lessons" />
      {
        page_load ? <Loading /> : <div className="db__height">
          <div className="lessons_container">
            <LessonLists
              completed={completed}
              lessons={lessons}
              current={current} />
          </div>
        </div>
      }
    </PageTitle>
  );
}

export default Lessons;
