import { PaymentsCard } from "../../components/cards/Card";
import { Navigation } from "../../components/navigation/Navigation";
import "./style.css";
import { PaymentsTable } from "./PaymentsTable";
import { useEffect, useState } from "react";
import PaymentService from "./PaymentService";
import { GlobalData } from "../../context/GlobalContext";
import { PageRedirect, Route, PageTitle } from "react-paget";
import Loading from "../../components/Loading";

function Payments() {
  const [paymnetSummary, setPaymentSummary] = useState([
    { id: 1, title: "Course Fee", fee: "0" },
    { id: 2, title: "Amount Paid", fee: "0" },
    { id: 3, title: "Balance Remaining", fee: "0" },
    // { id: 4, title: "Outstanding Balance", fee: "0" },
  ]);
  const init_data = [
    { id: 1, sn: 1, amount: "56,900", currency: "₦", date: "28/08/2023", method: "Bank Transfer", status: "Approved" },

  ]
  let [payments, setPayment] = useState(init_data)
  let [page_load,setPageLoad] = useState(true);

  let { store } = GlobalData();
  let detail = store?.DETAIL;


  useEffect(() => {

    let user_id = detail?.id;
    PaymentService.getPayment(user_id)
      .then(res => {

        if (res) {
          let arr = [
            { id: 1, title: "Course Fee", fee: res?.summary?.course_fee },
            { id: 2, title: "Amount Paid", fee: res?.summary?.amount_paid },
            { id: 3, title: "Balance Remaining", fee: res?.summary?.balance }
          ]

          let table = res?.data?.map((e, i) => ({
            id: e.id,
            sn: i + 1,
            amount: e.paid,
            currency: "₦",
            date: e.date_paid,
            channel: e.channel,
            status: e.status
          })) || [];

          setPaymentSummary(arr);
          setPayment([...table]);
          setPageLoad(false)
        }

      });

  }, [store]);

  return (
    <PageTitle title="Portal-Payment">
      <Navigation title="Payments" />
      {page_load? <Loading/> :  <div className="db__height">
        <div className="payments_container">
          <div className="payments_cards">
            {paymnetSummary.map((payment) => (
              <PaymentsCard key={payment.id} title={payment.title} descrp={payment.fee} />
            ))}
          </div>
          <div className="payments_table_container">
            <PaymentsTable payments={payments} profile={detail} />
          </div>
        </div>
      </div>}
    </PageTitle>
  );
}

export default Payments;
