import { useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import "./style.css";
import { IoTimeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import img from "../../assets/img/liveclass.jpg";

function LiveClassWaiting() {
  return (
    <div className="awaiting">
      <p>
        Live classes are specialized training sessions, Q&A sessions set up regularly by trainers, supervisors, or mentors, to assist trainees understand core concepts,
        instructor led practice classes
      </p>

      <p>Trainees are to attend a minimum one live class a week. most live classes will be graded and part of the overall program.</p>
      <img src={img} alt="Live Class" />
      <h5>Your Live classes will apppear here.</h5>
    </div>
  );
}

function LiveClass() {
  const [liveClesses, setLiveClasses] = useState([
    {
      id: 1,
      title: "HTML & CSS",
      description:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis explicabo aspernatur iste sed sequi voluptatum officia vel expedita tenetur. Maxime.",
      start_date: "Tue, 16 Jan, 2024",
      start_time: "10:00am CAT",
      status: "completed",
    },
    // {
    //   id: 2,
    //   title: "Git & GitHub",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis explicabo aspernatur iste sed sequi voluptatum officia vel expedita tenetur. Maxime.",
    //   start_date: "Tue, 16 Jan, 2024",
    //   start_time: "10:00am CAT",
    //   status: "missed",
    // },
    // {
    //   id: 3,
    //   title: "JavaScript",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis explicabo aspernatur iste sed sequi voluptatum officia vel expedita tenetur. Maxime.",
    //   start_date: "Tue, 16 Jan, 2024",
    //   start_time: "10:00am CAT",
    //   status: "live",
    // },
    // {
    //   id: 4,
    //   title: "Bootstrap",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis explicabo aspernatur iste sed sequi voluptatum officia vel expedita tenetur. Maxime.",
    //   start_date: "Tue, 16 Jan, 2024",
    //   start_time: "10:00am CAT",
    //   status: "upcoming",
    // },
  ]);
  return (
    <>
      <Navigation title="Live Classes" />
      <div className="live_class">
        {liveClesses.length ? (
          <div className="live_classes_grid">
            {liveClesses.map((classes) => (
              <Link to={`/live_classes/${classes.id}`} className="live_classes" key={classes.id}>
                <span className={`live_classes_status ${classes.status ? classes.status : ""}`}>{classes.status}</span>
                <div className="live_classes_bg"></div>
                <div className="live_classes_content">
                  <h4>{classes.title}</h4>
                  <p>
                    <IoTimeOutline />
                    {classes.start_date} {classes.start_time}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <LiveClassWaiting />
        )}
      </div>
    </>
  );
}

export default LiveClass;
