import axios from 'axios';
import config from "../config"

class NotificationService{

    getNotice(student_id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id/notice").bind(student_id);
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    markAsRead(notice_id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/notice/:notice_id/is-read").bind(notice_id);
        return new Promise((resolve,reject) => {
         axios.put(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

}

export default new NotificationService;