import { useEffect, useRef, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import Img from "../../assets/img/Rework-Academy-Logo.png";
import "./style.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PaymentService from "../Payment/PaymentService";
import { SyncLoader } from "react-spinners";
import Trove from "react-trove";
import { GlobalData } from "../../context/GlobalContext";
import { useCookies } from 'react-cookie';

function LoginPayment({ profile }) {

  const [fileName, setFileName] = useState("");
  const [paymentFile, setPaymentFile] = useState("");

  const CURRENCY = profile?.learning_mode?.toUpperCase() === "SELF_PACE" ? "USD" : "NGN";
  const user_id = profile?.id;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [hidden, setHidden] = useState(true);
  const [,removeCookie] = useCookies();
  const linkRef = useRef()

  let { store, } = GlobalData();
  

  // const amounts = [
  //   {
  //     id: "1",
  //     usd: "25",
  //     ngn: "25,000",
  //     type: "monthly"
  //   },
  //   {
  //     id: "2",
  //     usd: "71.25",
  //     ngn: "71,250",
  //     type: "quaterly"
  //   },
  //   {
  //     id: "3",
  //     usd: "270",
  //     ngn: "270,000",
  //     type: "yearly"
  //   }
  // ]
  const amounts = [
    {
      id: "1",
      usd: "25",
      ngn: "25000",
      type: "monthly"
    },
    {
      id: "2",
      usd: "71.25",
      ngn: "71250",
      type: "quaterly"
    },
    {
      id: "3",
      usd: "270",
      ngn: "270000",
      type: "yearly"
    }
  ]
  const currencies = [
    {
      id:1,
      value:"NGN"
    },
    // {
    //   id:2,
    //   value:"USD"
    // }
  ]


  const handleCurrencySelection = (value) => {
    setCurrency(value);
    setHidden(false);
    if(selected){
      setAmount(value === 'USD' ? selected.usd : selected.ngn)
    }
  }

  const handleAmountSelected = (value) => {
    setSelected(value)
    setAmount(currency === "USD" ? value.usd : value.ngn)
  }
  
  const handleUploadPayment = () => {

    setLoading(true);
    PaymentService
      .uploadPayment(user_id, paymentFile)
      .then(e => {

        setLoading(false);
        if (e.type === "SUCCESS") setPaymentFile("");

      });

  }

  const handleOnlinePayment = () => {
    setLoading(true);
   
    let email = profile.email;
    let full_name = profile.first_name + ' ' + profile.last_name;
    let student_id = profile.student_id;
    let phone = profile.phone;
    let country = profile.country;

    let data = {
      email,
      user_id,
      student_id,
      amount,
      phone,
      full_name,
      currency,
      country,
      payment_period:selected.type.toUpperCase(),
      vendor:"SQUAD"
    }

    PaymentService
      .getPaymentLink(data)
      .then(res => {
        setLoading(false);
        
        if (res.status?.toUpperCase() === 'SUCCESS') {

         window.location.href = res.data.link;
          // linkRef.current.setAttribute('href',res.data.link)
          // linkRef.current.setAttribute('target','_blank')
          // linkRef.current.click()

          // store['TX_REF'] = {
          //   id:res?.data?.tx_ref,
          //   status:false
          // }

          // Trove.resave(store);
          
        }

      });

  }


  return (
    <div className="lp__container">
      <div className="lp__content form">
        <form>
          <div className="lp__header">
            <img src={Img} alt="Logo" className="logo" />
            <h2>Make payment</h2>
            <a ref={linkRef}></a>
          </div>

          <Tabs>
            <TabList>
              {profile?.learning_mode?.toUpperCase() === "SELF_PACE" ? "" : <Tab>Bank Transfer</Tab>}
              <Tab>Credit / Dedit Card</Tab>
            </TabList>
            {
              profile?.learning_mode?.toUpperCase() === "SELF_PACE" ? "" : <TabPanel>
                <div className="lp__header">
                  <p>Kindly make a payment as a bank transfer, or any other accepted means of payment to the account details as shown below.</p>
                </div>

                <div className="lp__body">
                  <div className="lp__details">
                    <h3>
                      <span>Bank Name:</span> GTBank
                    </h3>
                    <h3>
                      <span>Account Name:</span> Rework Technologies Limited
                    </h3>
                    <h3>
                      <span>Account Number:</span> 0500709165
                    </h3>
                  </div>
                  <div className="lp__details-upload">
                    <label htmlFor="lp_upload" className="lp__upload">
                      <IoCloudUploadOutline className="lp__upload-icon" />
                      <p>{fileName ? fileName : "Kindly upload proof of payment"}</p>
                    </label>
                    <input type="file" id="lp_upload" onChange={(e) => {
                      setPaymentFile((prev) => (prev = e.target.files[0]));
                      setFileName((prev) => (prev = e.target.files[0].name));
                    }} />
                  </div>

                </div>
                <div className="lp__btn">
                  <button 
                    className="button primary shadow" 
                    onClick={loading ? "" :handleUploadPayment} 
                    type="button">
               
                    {loading ? <SyncLoader color="#00afef" size={8} /> : " Submit"}
                    </button>
                </div>
              </TabPanel>
            }
            <TabPanel>
              <div className="lp__header">
                <p>Payment for a month or more is allowed.</p>
              </div>
              <div className="form" style={{ padding: "0" }}>
                <div className="form-group">
                  <label>Fullname</label>
                  <input type="text" value={`${profile?.first_name} ${profile?.last_name}`} disabled />
                </div>

                <div className="form-group">
                  <label>Currency</label>
                  <div>
                    {
                      currencies.map(e=> <div className="flex" key={e.id}>
                      <input
                        type="radio"
                        name="currency"
                        value={e.value}
                        id={e.value}
                        onChange={e => handleCurrencySelection(e.target.value)}
                      />
                      <label htmlFor={e.value}>{e.value}</label>
                    </div>)
                    }
                   
                    {/* <div className="flex">
                      <input
                        type="radio"
                        name="currency"
                        value="USD"
                        id="USD"
                        onChange={e => handleCurrencySelection(e.target.value)}
                      />
                      <label htmlFor="USD">USD</label>
                    </div> */}
                  </div>
                </div>

                {
                  !hidden ? <div className="form-group">
                    <label>Payment Options</label>
                    <div>
                      {
                        amounts.map(e => <div className="flex" key={e.id}>
                          <input
                            type="radio"
                            name="amount"
                            value={ currency === "USD" ? e.usd : Number(e.ngn).toLocaleString() }
                            id={e.type}
                            data-attr={e.id}
                            onChange={() => handleAmountSelected(e)}
                          />
                          <label htmlFor={e.type}>{e.type} {currency === "USD" ? "$" + e.usd : "₦" + e.ngn}</label>
                        </div>)
                      }
                    </div>
                  </div>
                    : ""
                }
         
                <button
                  className="button primary shadow"
                  type="button"
                  style={{ width: "100%" }} 
                  onClick={loading ? ()=>{} : !amount && !currency ? ()=>{} : handleOnlinePayment}>
                  
                    {loading ? <SyncLoader color="#00afef" size={8} /> : "Make Payment"}
                  </button>
              </div>
            </TabPanel>

          </Tabs>

        </form>
      </div>
    </div>
  );
}

export default LoginPayment;
