import EditHeader from "./EditHeader";
import { ProfileData } from "../../context/ProfileContext";

export default function ProfileCourse() {
  const { courses } = ProfileData();

  return (
    <div className="profile-courses">
      <EditHeader heading={"Course(s)"}></EditHeader>
      <div className="profile-course-text">
        {courses.map((course) => (
          <h4 key={course.id}>
            {course.course} - ({course.status})
          </h4>
        ))}
      </div>
    </div>
  );
}
