import axios from 'axios';
import config from "../config"


class AssessmentService{

    getAssessment(user_id,assessment_id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id/assessments/:assessment_id").bind(user_id,assessment_id);
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getReview(receipient_id,assessment_id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/assessments/reviews").query({receipient_id,assessment_id});
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    createReview(data){

        data.type = "STUDENT";
        data.receipient_id = data.sender_id;

        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/assessments/reviews");
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    submitAssessment(data){

      let url;
      let formData = new FormData();
      
      formData.append("assessment_id",data.assessment_id);
      formData.append("phase_id",data.phase_id);
      formData.append("user_id",data.user_id);
      formData.append("resource",data.resource);

      url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/assessments/submit");
      
      return fetch(url, {
         method: "POST",
         body: formData,
     })
     .then((res) => res.json());

  }

}

export default new AssessmentService;