import { useState } from "react";
import { IoCloudUploadSharp, IoDocumentTextSharp } from "react-icons/io5";
import AssessmentService from "./AssessmentService";

export default function ProjectSubmission({submissions,assessment,user_id,setSubmission}) {
 
  const [projectFile, setProjectFile] = useState("Select to Upload Poject");
  const [file, setFile] = useState("");

  const uploadProject = (e) => {
    setProjectFile(e.target.files[0].name);
    setFile(e.target.files[0]);
  };

  const handleAssessmentUpload = () => {
   
    let data = { };
    let arr = [];
   
    data.phase_id = assessment?.current_phase;
    data.assessment_id = assessment?.id;
    data.resource = file;
    data.user_id = user_id;

    AssessmentService.submitAssessment(data).
    then(res =>{
        arr = [...submissions,...[res.data]];
        setSubmission(arr);

    });

  }

  return (
    <div className="project_submission">
      <div className="PS_header">
        <h2>Submit Project (In Phases)</h2>
        <p>Be sure to zip any project folder you wish to submit before submitting it.</p>
      </div>
      <form className="PS_form">
        <input type="file" id="submitProject" onChange={uploadProject} />
        <label htmlFor="submitProject">
          <IoCloudUploadSharp />
          <span>{projectFile}</span>
        </label>
        <button type="button" className="button primary" onClick={handleAssessmentUpload}>Submit</button>
      </form>
      <div className="PS_submitted">
        {submissions.map((project) => (
          <div className="PS_submitted_contents" key={project.submitted_date}>
            <div className="PS_submitted_content_img">
              <IoDocumentTextSharp />
            </div>
            <div className="PS_submitted_content_text">
              <h5>{project.name}-{project.file_name}</h5>
              <p>{project.submitted_date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
