export default function Elapsed () {
    return (
      <div className="project__timer expired flex space-between">
        <h3>
              0 <span>Days</span>
          </h3>
          <h3>
              0 <span>Hours</span>
          </h3>
          <h3>
              0 <span>Mins</span>
          </h3>
          <h3>
             0 <span>Secs</span>
          </h3> 
      </div>
    );
  };