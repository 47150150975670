import { useState, useContext, createContext } from "react";

export const SidebarContext = createContext();
export const SidebarData = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {

  const [toggle, setToggle] = useState(false);
  const [loginStatus, setLoginStatus] = useState("student");
  const [sidebar, setSideBar] = useState([]);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };
  
  const values = { 
    toggle, 
    sidebar, 
    setSideBar,
    setToggle, 
    handleToggle, 
    loginStatus, 
    setLoginStatus 
  };

  return <SidebarContext.Provider value={values}>{children}</SidebarContext.Provider>;
};
