import { useState,useEffect } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import CertificateDesign from "./CertificateDesign";
import LogoBgBlue from "../../assets/img/LogoBg-blue.png";
import LogoBgWhite from "../../assets/img/LogoBg-white.png";
import LogoBlue from "../../assets/img/Rework-Academy-Logo.png";
import LogoWhite from "../../assets/img/Rework-Academy-(Fill-white).png";
import CertificateService from "./CertificateService";
import { GlobalData } from "../../context/GlobalContext";
import { PageRedirect, PageTitle, Route } from "react-paget";
import Loading from '../../components/Loading'

import "./style.css";

function Certificates() {
  let [certificates,setCertificate] = useState([
    { id: 1, course: "Frontend Development with React JS", full_name: "Nuhu Ahmed", certificate_id: "123456789", date_issued: "12/12/2023" },
    // { id: 2, course: "Full Stack Web Development with Node JS", full_name: "Nuhu Ahmed", certificate_id: "123456789", date_issued: "12/12/2023" },
    // { id: 3, course: "Data Analytics with Excel & Power BI", full_name: "Nuhu Ahmed", certificate_id: "123456789", date_issued: "12/12/2023" },
    // { id: 4, course: "Data Science and Machine Learning with Python", full_name: "Nuhu Ahmed", certificate_id: "123456789", date_issued: "12/12/2023" },
    // { id: 5, course: "UI / UX Design", full_name: "Nuhu Ahmed", certificate_id: "123456789", date_issued: "12/12/2023" },
  ]);
  let { store } = GlobalData();
  let detail = store?.DETAIL;
  let user_id = detail?.id
  let [page_load,setPageLoad] = useState(true);

  useEffect(()=>{
   
   if(user_id){
    CertificateService.getCertificate(user_id)
    .then(e =>{
      setCertificate([...e])
      setPageLoad(false)
    });
   }
    
  },[store]);

  return (
    <PageTitle title="Portal-Certificate">
      <Navigation title="Certificates" />
      {
        page_load ? <Loading/> :  <div className="db__height">
        {certificates.map((certificate) =>
          certificate.course === "Full Stack Web Development with Node JS" ? (
            <CertificateDesign key={certificate.id} data={certificate} bg="#00a64d" color="#ffffff" border="#fffffff" LogoBg={LogoBgWhite} Logo={LogoWhite} />
          ) : certificate.course === "Frontend Development with React JS" ? (
            <CertificateDesign key={certificate.id} data={certificate} bg="#fdfefe" border="#00afef" color="#231f20" LogoBg={LogoBgBlue} Logo={LogoBlue} />
          ) : certificate.course === "Data Analytics with Excel & Power BI" ? (
            <CertificateDesign key={certificate.id} data={certificate} bg="#fba91e" color="#ffffff" border="#ffffff" LogoBg={LogoBgWhite} Logo={LogoWhite} />
          ) : certificate.course === "Data Science and Machine Learning with Python" ? (
            <CertificateDesign key={certificate.id} data={certificate} bg="#3470a0" color="#ffffff" LogoBg={LogoBgWhite} border="#ffffff" Logo={LogoWhite} />
          ) : certificate.course === "UI / UX Design" ? (
            <CertificateDesign key={certificate.id} data={certificate} bg="#DDC69A" color="#755D41" LogoBg={LogoBgWhite} border="#755D41" Logo={LogoWhite} />
          ) : null
        )}
      </div>
      }
    </PageTitle>
  );
}

export default Certificates;
