import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// TEMPLATES
import Progress from "./templates/progress/Progress";
import Button from "./templates/buttons/Button";

// LAYOUT
import DashboardLayout from "./layouts/DashboardLayout";

// CONTEXT
import { SidebarProvider } from "./context/SidebarContext";
import { VideoProvider } from "./context/VideoContext";
import { GlobalData } from "./context/GlobalContext";
import { ProfileProvider } from "./context/ProfileContext";

import { useStore } from "react-trove";

// PAGES
import Login from "./pages/Login/Login";

import LoginPayment from "./pages/LoginPayment/LoginPayment";
import PassportUpload from "./pages/PassportUpload/PassportUpload";
import Dashboard from "./pages/Dashboard/Dashboard";
import EntryProject from "./pages/EntryProject/Project";
import Modal from "./templates/modal/Modal";
import CoursesModules from "./pages/CourseModules/CoursesModules";
import ModuleDetails from "./pages/ModuleDetails/ModuleDetails.";
import Projects from "./pages/Projects/Projects";
import SingleProject from "./pages/SingleProject/SingleProject";
import Payments from "./pages/Payment/Payments";
import PaymentReceipt from "./pages/Payment/PaymentReceipt";
import PaymentStatus from "./pages/Payment/PaymentStatus";
import Attendance from "./pages/Attendance/Attendance";
import Notifications from "./pages/Notifications/Notifications";
import Profile from "./pages/Profile/Profile";
import Certificates from "./pages/Certificates/Certificates";
import Password from "./pages/Password/Password";
import Lessons from "./pages/Lessons/Lessons";
import LessonDetail from "./pages/LessonDetails/LessonDetail";
import PeerPairing from "./pages/PeerPairing/PeerPairing";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import UnAuthorizedPage from "./pages/ErrorPage/UnAuthorizedPage";
import Welcome from "./pages/welcomePage/Welcome";
import LiveClass from "./pages/LiveClass/LiveClass";
import LiveClassDetail from "./pages/LiveClass/LiveClassDetail";

import { Outlet, Navigate, useLocation } from 'react-router-dom';

import { useCookies } from 'react-cookie'
import StudyGroup from "./pages/StudyGroup/StudyGroup";

function AuthenticateRoute() {
  let [cookie] = useCookies()
  let auth = cookie.logged ? true : false
  return auth ? <Outlet /> : <Navigate to="/" />
}

function AuthorizedRoute() {
  let [cookie] = useCookies()
  let location = useLocation();
  let auth = cookie.paid ? true : false

  return auth ? <Outlet /> : <Navigate
    to="/unauthorized-route"
    state={location.pathname}
    replace={true} />
}

function CompletedRoute() {
  let [cookie] = useCookies()
  let location = useLocation();
  let auth = cookie.completed ? true : false

  return auth ? <Outlet /> : <Navigate
    to="/unauthorized-route"
    state={location.pathname}
    replace={true} />
}

function App() {
  const { store, setStore } = GlobalData();

  useStore(store, setStore);


  return (
    <SidebarProvider>

      <VideoProvider>
        <ProfileProvider>
          <Router>
            <Routes>
              <Route path="*" element={<ErrorPage />} />
              <Route path="/" element={<Login />} />
              <Route path="/unauthorized-route" element={<UnAuthorizedPage />} />
              <Route path="/payments/status" element={<PaymentStatus />} />

              <Route element={<AuthenticateRoute />}>
                <Route path="/payment" element={<LoginPayment />} />
                <Route path="/passport_upload" element={<PassportUpload />} />

                <Route element={<DashboardLayout />}>

                  <Route path="/entry-project" element={<EntryProject />} />
                  
                  <Route element={<CompletedRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/settings" element={<Password />} />
                  </Route>

                  <Route element={<AuthorizedRoute />}>
                    <Route path="/modules" element={<CoursesModules />} />
                    <Route path="/modules/:id" element={<ModuleDetails />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/assignments" element={<Projects />} />
                    <Route path="/assignments/:id" element={<SingleProject />} />
                    <Route path="/projects/:id" element={<SingleProject />} />
                    <Route path="/payments" element={<Payments />} />
                    <Route path="/payments/:id" element={<PaymentReceipt />} />
                    <Route path="/attendance" element={<Attendance />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/certificates" element={<Certificates />} />
                    <Route path="/lessons" element={<Lessons />} />
                    {/* <Route path="/lessons/:name" element={<LessonDetail />} /> */}
                    <Route path="/lessons/:name/:url" element={<LessonDetail />} />
                    <Route path="/study_group" element={<StudyGroup />} />
                    <Route path="/live_classes" element={<LiveClass />} />
                    <Route path="/live_classes/:id" element={<LiveClassDetail />} />
                  </Route>

                </Route>
                <Route path="/docs/button" element={<Button />} />
                <Route path="/docs/progress-bar" element={<Progress />} />
                <Route path="/docs/modal" element={<Modal />} />

              </Route>

            </Routes>
          </Router>
        </ProfileProvider>
      </VideoProvider>

    </SidebarProvider>
  );
}

export default App;
