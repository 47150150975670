function Modal({ setIsOpen, children, title,onClick }) {
  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)}></div>
     
      <div className="modal--container">
        <div className="profile--modal">
          <div className="modal--head">
            <h3 className="modal--heading">{title}</h3>
            <button className="modal--close-btn" onClick={() => setIsOpen(false)}>
              x
            </button>
          </div>
          <div className="modal--body">{children}</div>
          <div className="modal--actions-btns">
            <button className="modal--submit-btn button primary" onClick={onClick}>Submit</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
