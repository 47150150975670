import { createContext, useState } from "react";
import { useContext } from "react";

export const VideoContext = createContext();
export const VideoData = () => useContext(VideoContext);


let init_options = {
  autoplay: false,
  controls: true,
  fullscreen: true,
  playbackRates: [0.5, 0.7, 1, 1.25, 1.5, 2],
  responsive: true,

  userActions: {
    hotkeys: true,
  },
  fluid: true,
  width: 640,
  // poster:
  // "https://images.unsplash.com/photo-1703235452391-ef0a27926f9d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw3fHx8ZW58MHx8fHx8",
  sources: [
  ],

  plugins: {
    hotkeys: {
      volumeStep: 0.1,
      seekStep: 5,
      enableMute: true,
      enableVolumeScroll: true,
      enableFullscreen: true,
      enableNumbers: true,
      enableModifiersForNumbers: false,
      enableHoverScroll: true,
      captureDocumentHotkeys: true,
      documentHotkeysFocusElementFilter: (e) =>
        e.tagName.toLowerCase() === "body",
      playPauseKey: function (event, player) { },
      muteKey: function (event, player) { },
      fullscreenKey: function (event, player) {
        // override fullscreen to trigger when pressing the F key or Ctrl+Enter
        return event.which === 70 || (event.ctrlKey && event.which === 13);
      },
    },

    // record: {
    //   audio: true,
    //   video: true,
    //   maxLength: 10,
    //   debug: true
    // }
    // videoJsResolutionSwitcher: {
    //   default: 'low', // Default resolution [{Number}, 'low', 'high'],
    //   dynamicLabel: true
    // }
  },
};


export const VideoProvider = ({ children }) => {

  const [video_url, setVideoUrl] = useState();
  const [playlist, setPlaylist] = useState([])
  const [options, setOptions] = useState(init_options);

  const vals = {
    playlist,
    setPlaylist,
    video_url,
    setVideoUrl,
    options,
    setOptions
  };

  return <VideoContext.Provider value={vals }>{children}</VideoContext.Provider>;
};
