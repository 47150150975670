import { useEffect, useState } from "react";
import { PageTitle, PageRedirect } from "react-paget";

import { Navigation } from "../../components/navigation/Navigation";
import { ProjectUpload } from "./ProjectUpload";
import { ProjectDownload } from "./ProjectDownload";
import Trove from 'react-trove'

import { GlobalData } from "../../context/GlobalContext";

import Img from "../../assets/img/coding.svg";

import EntryProject from "./EntryProjectService";

import "./style.css";
import Loading from '../../components/Loading'


function AssessmentProject() {
  // Trove.remove();

  const { store } = GlobalData();
  const [project, setProject] = useState()
  const [page_load, setPageLoad] = useState(true);
  const [submission, setSubmission] = useState(0)

  useEffect(() => {

    if (store) {

      const user_id = store?.DETAIL?.id;

      if (user_id) {

        EntryProject
          .getCurrentProject(user_id)
          .then(res => {
            setPageLoad(false)
            if (res.type?.toUpperCase() === "NOT_ASSIGNED") {
            }
            if (res.project_id) {
              let trial = res?.phases[0].submission_trials - res?.phases[0].submitted
              setSubmission(trial)
              setProject(res);
            }
          });
      }

    }

  }, [store]);

  return (
    <>
      <PageTitle title="Portal- Entry Project">
        <Navigation title="Project" />
        {
          page_load ? <Loading /> : <div className="space-top project__container">
            <div className="project__img">
              <img src={Img} alt="Project" />
            </div>
            <div className="project__right">

              {
                !project ? <div className=".project__timer"><h3>No project assigned yet</h3></div> : <>
                  <ProjectDownload project={project} submission={submission} />
                  <ProjectUpload
                    submission={submission}
                    setSubmission={setSubmission}
                    student={store?.DETAIL}
                    store={store}
                    project={project} /></>
              }

            </div>
          </div>

        }


      </PageTitle>
    </>
  );
}

export default AssessmentProject;
