import axios from 'axios';
import config from "../config"


class ProjectService{

    get(student_id,type){
        
        let url =  (config.MODE==="DEV" ? config.DEV : config.PROD).concat("/students/:students_user_id/assessments")
        .bind(student_id)
        .query({type});

        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

}

export default new ProjectService;