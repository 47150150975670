import './app.css'
import Success from '../../assets/svg/success.svg'
import Error from '../../assets/svg/error.svg'
import {useSearchParams} from 'react-router-dom';
import {useEffect,useState} from 'react';
import PaymentService from './PaymentService';


function PaymentStatus() {
 
    const [searchParams, setSearchParams] = useSearchParams();
    const tx_ref = searchParams.get("reference")
    const transaction_id = searchParams.get("transaction_id")
 
    const [status,setStatus] = useState("")
    const [trans,setTrans] = useState({})

    useEffect(()=>{
     
   let interval = setTimeout(()=>{
                window.top.close()
        },4000)
        document.title ="Payment Status"
        PaymentService
        .getPaymentByTransactionId(tx_ref)
        .then(res=>{
            setStatus(res.status)
            setTrans(res)

            
        })

        return ()=> clearInterval(interval)
    },[])

    return <div className="bg-blue-600 text-white min-h-screen p-10">
        <div className="max-w-sm mx-auto bg-white">
            {/* <div className="flex justify-between">
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                   
                    className="h-6 w-6 cursor-pointer hover:text-blue-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                </svg>
                <h1 className="font-bold text-lg">Pay money</h1>
                <div className="h-6 w-6"></div>
            </div> */}

            <div className="flex justify-center my-8 mx-auto check-wrap" style={{ maxwidth: "15rem",marginTop:"5em",marginBottom:"5em" }}>
                {status === "APPROVED" ? <img alt="Success SVG Vector Icon" fetchpriority="high" width="150" height="150" decoding="async" data-nimg="1" style={{color:"transparent",width:"100px",height:"100px"}} src={Success}/> :
                <img alt="Success SVG Vector Icon" fetchpriority="high" width="150" height="150" decoding="async" data-nimg="1" style={{color:"transparent",width:"100px",height:"100px"}} src={Error}/>}
            </div>

            <h2 className="text-center text-3xl">Payment {status === "APPROVED" ? "success":"failed"}</h2>
            <div className="text-center text-2xl font-bold">
                {trans.currency === "NGN"? "₦" : "$"}{Number(trans.amount).toLocaleString()}
            </div>

            <div className="text-center bg-blue-500 rounded-lg p-8 flex flex-col space-y-1 my-8" style={{color:"#fff"}}>
                <div>Your payment {status === "APPROVED" ? "was successfully sent to" :"failed to send to"}</div>
                <div className="font-bold underline cursor-pointer hover:text-blue-100">Rework Academy</div>
                <div>{new Date(trans.created_at).toLocaleString()}</div>
                <div>
                    Payment ID:
                    <span className="font-bold underline cursor-pointer hover:text-blue-100">{tx_ref}</span>
                </div>
            </div>

            <div className="flex justify-center">
                <button className="transition px-12 py-4 rounded-lg" style={{backgroundColor:"transparent"}}>
                   Back to site
                </button>
            </div>
        </div>
    </div>
}

// style="padding: 40px"

export default PaymentStatus