import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EditHeader from "./EditHeader";
import editIcon from "../../assets/img/edit.svg";
import Modal from "../../components/profile/Modal";
import { ProfileData } from "../../context/ProfileContext";

export default function ProfileProject() {

  const { project } = ProfileData();
  const [isOpen, setIsOpen] = useState(false);
  const [ongoing, setOngoing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  const checkOngoing = (e) => {
    const checked = e.target.checked;
    checked ? setOngoing(true) : setOngoing(false);
  };

  return (
    <>
      <EditHeader heading={"Projects"} >
        <button className="icon" onClick={() => setIsOpen(true)} style={{display:"none"}}>
          <img src={editIcon} alt="Edit" />
          <span>Add</span>
        </button>
      </EditHeader>
      <div className="profile-projects-list">
        {project.length ? (
          project.map((data) => (
            <div className="project-list" key={data.id}>
              <h4>{data.title}</h4>
              <p>{data.descrp}</p>
              <p>
                {data.start_date} - {data.end_date}
              </p>
              <p>Status: {data.status}</p>
              <Link to={"/"}>View</Link>
            </div>
          ))
        ) : (
          <p className="profile-certificate-cert">No Project Uploaded!</p>
        )}
      </div>
      {isOpen && (
        <Modal setIsOpen={setIsOpen} title={"Add Project"}>
          <form className="form profile--form">
            <div className="form-group">
              <label htmlFor="project_name">Project name</label>
              <input type="text" />
            </div>
            <div className="form-group-sub">
              <input type="checkbox" onChange={(e) => checkOngoing(e)} />
              <span>Currently working on project</span>
            </div>
            <div className="form-group">
              <label htmlFor="Start date">Start Date</label>
              <input type="date" />
            </div>
            <div className="form-group">
              <label htmlFor="Start date">{ongoing ? "Expected End Date" : "End Date"}</label>
              <input type="date" />
            </div>
            <div className="form-group">
              <label htmlFor="collorators">Add Collaborator(s)</label>
              <input type="text" />
            </div>
            <div className="form--grid">
              <div className="form-group">
                <label htmlFor="collorators">Course</label>
                <select>
                  <option value="">Select Course</option>
                  <option value="Full Stack Development with NODE JS">Full Stack Development with NODE JS</option>
                  <option value="Full Stack Development with PHP">Full Stack Development with PHP</option>
                  <option value="Data Science with Python">Data Science with Python</option>
                  <option value="Data Science with Python">Data Science with Python</option>
                  <option value="Frontend Development with React JS">Frontend Development with React JS</option>
                  <option value="Data Analytics with Excel and Power BI">Data Analytics with Excel and Power BI</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="collorators">Module</label>
                <select>
                  <option value="">Select Module</option>
                  <option value="HTML & CSS">HTML & CSS</option>
                  <option value="Beginner Javascript">Beginner Javascript</option>
                  <option value="jQuery & Ajax">jQuery & Ajax</option>
                  <option value="Bootstrap">Bootstrap</option>
                  <option value="Advanced javascript">Advanced javascript</option>
                  <option value="React JS">React JS</option>
                  <option value="Databes Modeling & Design">Databes Modeling & Design</option>
                  <option value="Node JS & MongoDB">Node JS & MongoDB</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="project_url">Project URL</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label htmlFor="project_descrp">Project Description</label>
              <textarea></textarea>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}
