import Img from "../../assets/img/Rework-Academy-Logo.png";

export const LoginHeader = () => {
  return (
    <div className="lc__header">
      <img src={Img} alt="Logo" className="logo" />
      <h4>Rework Portal</h4>
      <h2>Welcome Back</h2>
    </div>
  );
};
