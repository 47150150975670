import { useState, useEffect } from "react";
import EditHeader from "./EditHeader";
import editIcon from "../../assets/img/edit.svg";
import Modal from "../../components/profile/Modal";
import closeIcon from "../../assets/img/close.svg";
import { ProfileData } from "../../context/ProfileContext";

function ProfileSkills() {
  const [isOpen, setIsOpen] = useState(false);
  const { skills, setSkills } = ProfileData();
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  return (
    <>
      <EditHeader heading={"Skills"}>
        <button className="icon" onClick={() => setIsOpen(true)} style={{display:"none"}}>
          <img src={editIcon} alt="Edit" />
          <span>Add</span>
        </button>
      </EditHeader>
      <div className="profile-skills-list">
        <div className="skills--lists">
          {skills.map((skill, i) => (
            <p className="skill-list" key={i}>
              {skill}
            </p>
          ))}
        </div>
      </div>
      {isOpen && (
        <Modal setIsOpen={setIsOpen} title={"Add Skills"}>
          <div className="modal--sub-heading">
            <p>Add skillset for people to see</p>
          </div>
          <div className="pill--btns">
            {skills.map((skill, index) => (
              <button className="pill-btn" key={index}>
                {skill}
                <img src={closeIcon} alt="Close" className="pill-close" />
              </button>
            ))}
          </div>
          <form className="skills-form">
            <div className="form-group">
              <label htmlFor="add_skill">Add other skills</label>
              <input type="text" />
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}

export default ProfileSkills;
