import { useNavigate } from "react-router-dom";
import "./style.css";

function ErrorPage() {
    let navigate = useNavigate()
  return (
    <div className="ErrorPage">
      <div className="ErrorPage_content">
        <h2>403</h2>
        <p>Unauthorized Route</p>
        <button className="button primary bg-btn-primary shadow" onClick={() => navigate(-1)}>Go back</button>
      </div>
    </div>
  );
}

export default ErrorPage;
