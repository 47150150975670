import { Link } from "react-router-dom";
import "./style.css";

function ErrorPage() {
  return (
    <div className="ErrorPage">
      <div className="ErrorPage_content">
        <h2>404</h2>
        <p>Page Does Not Exist</p>
        <Link to="/">Go Home</Link>
      </div>
    </div>
  );
}

export default ErrorPage;
