import { useState } from "react";
import { Navigation } from "../../components/navigation/Navigation";
// import "./style.css";
import { IoTimeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import img from "../../assets/img/studygroup.jpg";



function GroupAwaiting() {
    return (
      <div className="awaiting">
        <p>During the program at the Academy, each Trainee is placed into a Study Groups with their peers, this Study Groups meet daily(minimum 2 hours weekly).</p>
        <p>Study Group allow trainees to build relationships, get Help/support, and receive feedback on the Assignments Understand the Agile team practice.</p>
        <p>
          The Study Groups are rotated during the Batch. Trainees will coordinate with other Study Group peers directly to set up your meeting times, Venues and Team Lead.
        </p>
        <img src={img} alt="Study Group" />
        <h5>Your Study group appears here when you have been assigned to one.</h5>
      </div>
    );
  }

  

function StudyGroup() {
    const [liveClesses, setStudyGroupes] = useState([]);

    return (
        <>
            <Navigation title="Live Classes" />
            <div className="studygroup">
                {/* {liveClesses.length > 1 ? (
                    <div className="live_classes_grid">
                        {liveClesses.map((classes) => (
                            <Link to={`/live_classes/${classes.id}`} className="live_classes" key={classes.id}>
                                <span className={`live_classes_status ${classes.status ? classes.status : ""}`}>{classes.status}</span>
                                <div className="live_classes_bg"></div>
                                <div className="live_classes_content">
                                    <h4>{classes.title}</h4>
                                    <p>
                                        <IoTimeOutline />
                                        {classes.start_date} {classes.start_time}
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>
                ) : (
                    <LiveClassWaiting />
                )} */}
               < GroupAwaiting/>
            </div>
        </>
    );
}

export default StudyGroup;
