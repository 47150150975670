import { Navigation } from "../../components/navigation/Navigation";
import PasswordService from "./PasswordService";
import { GlobalData } from "../../context/GlobalContext";
import { useState,useRef } from "react";
import { PageRedirect, Route,PageTitle } from "react-paget";
import './style.css';

function Password() {

  let { store } = GlobalData();
  let regMsg = useRef();

  let detail = store?.DETAIL;
  let [data,setData] = useState({
    email: detail?.email,
    old_passwd: "",
    new_passwd: "",
  })

  let [message,setMessage] = useState("");

  let handleClick = () => {

    PasswordService.changePwd(data)
    .then(res =>{

      regMsg.current.classList.remove("error")
      regMsg.current.classList.remove("success")

      if(res.code == 404){
        regMsg.current.classList.add("error")
      }else{
        regMsg.current.classList.add("success")
      }
      setMessage(res.msg)
    })
  }

  return (
   
    <PageTitle title="Portal-Setting">
      <Navigation title="Password Settings" />
      <div className="db__height">
        <div className="password_content">
          <form className="form">
            <div className="form-group">
              <label>Current Password</label>
              <input type="password"  onChange={(e)=>setData({...data,['old_passwd']:e.target.value})}/>
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input type="password" value={data.new_passwd} onChange={(e)=>setData({...data,['new_passwd']:e.target.value})} />
            </div>
        
            <div className="msg password-error" ref={regMsg}>{message}</div>
            <button className="button primary" type="button" onClick={handleClick}>Update Password</button>
          </form>
        </div>
      </div>
    </PageTitle>

  );
}

export default Password;
