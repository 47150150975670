
import { IoPersonCircleSharp } from "react-icons/io5";

export const SidebarHeader = ({ profile }) => {

  let duration = profile?.course?.duration
  let value= ""
  if(duration){
    let arr = duration.split(" ");
    duration = arr[0];
    value = !(parseInt(duration) >= 1) ? arr[1] : arr[1]+'(s)';
  }
  return (
    <div className="sidebar__header">
      <div className="sb__header-top">
        <IoPersonCircleSharp className="sb__hd-icon" />
        <h3>
          {profile?.first_name} {profile?.last_name}
        </h3>
        <h4>{profile?.email}</h4>
        <p>{profile?.phone}</p>
      </div>
   
      {
       
        profile && !profile.has_made_payment ? "" : <div className="sb__header_bottom flex space-between">
          <div className="sbh__bottom">
            <h2>{duration}</h2>
            <span>{value}</span>
          </div>
          <div className="sbh__bottom">
            <h2>{profile?.course?.lectures}</h2>
            <span>modules</span>
          </div>
        </div>
      }

    </div>
  );
};
