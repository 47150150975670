import { useState, useEffect } from "react";
import EditHeader from "./EditHeader";
import editIcon from "../../assets/img/edit.svg";
import closeIcon from "../../assets/img/close.svg";
import Modal from "../../components/profile/Modal";
import { ProfileData } from "../../context/ProfileContext";
import ProfileService from "./ProfileService";

export default function ProfileLanguage({user_id}) {

  const [isOpen, setIsOpen] = useState(false);
  const { language,setLanguage,languages } = ProfileData();

  const [name,setName] = useState("");
  const [fluency,setFluency] = useState("");
  const [is_default,setIsDefault] = useState(false);

  const handleRemove = (e) => {
    let arr = language.filter(x => x.name !== e);
    setLanguage(arr);
  }

  const handleClickLanguage = ()=>{
   
    let selected;
    let lang = [...language];
    let data ={ languages:[] };
    let exist = lang.find(x => x.name === name);

    if(is_default){
      selected = lang.find(x=>x.is_default === true);
      if(selected) selected.is_default = false;
    }
    if(!exist && name){
      lang.push({name,fluency,is_default});
    }
    else{
      exist.is_default = is_default ? true : false;
      exist.fluency = fluency;
    }
    
    data = { languages: lang };

    setLanguage(lang);

    ProfileService.updateProfile(user_id,data)
    .then(e=>{

      if(e.type === "SUCCESS"){
        setIsOpen(false);
  
      }

    });

  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  return (
    <div className="profile-language">
      <EditHeader heading={"Primary Language Spoken"}>
        <button className="icon" onClick={() => setIsOpen(true)}>
          <img src={editIcon} alt="Edit" />
          <span>Add</span>
        </button>
      </EditHeader>
      <div className="profile-language-text">
        <h4>English</h4>
      </div>
      {isOpen && (
        <Modal setIsOpen={setIsOpen} title={"Add Language"} onClick={handleClickLanguage}>
          <div className="modal--sub-heading">
            <p>Include languages spoken to build yourself</p>
          </div>
          <div className="pill-btns">
            {language.map((lang) => (
              <button className="pill-btn" key={lang.name}>
                {lang.name}
                <img src={closeIcon} alt="Delete Language" className="pill-close" onClick={ ()=> handleRemove(lang.name)}/>
              </button>
            ))}
          </div>
          <form action="" className="form profile--form">
            <div className="form-group">
              <label htmlFor="language">Add Language</label>
              <select name="language" onChange={(e)=> setName(e.target.value)}>
              <option key={0} value=""></option>
                {
                  languages?.map(e=>(<option key={e.id} value={e.name}>{e.name}</option>))
                }
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="language">Proficiency Level</label>
              <select name="lang-proficiency" onChange={(e)=> setFluency(e.target.value)}>
                <option value=""></option>
                <option value="Speak only">Speak only</option>
                <option value="Read only">Read only</option>
                <option value="Write only">Write only</option>
                <option value="Read & Speak only">Read and Speak only</option>
                <option value="Read & Write only">Read and write only</option>
                <option value="Speak & Write only">Speak and Write only</option>
                <option value="Speak & Read only">Speak and Read only</option>
                <option value="Speak, Read & Write only">Speak, Read and Write</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="selected">Default Selection</label>
              <select name="lang-proficiency" onChange={(e)=> setIsDefault(e.target.value? true: false)}>
                <option value=""></option>
                <option value="true">True</option>
              </select>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
