import { Card } from "../../components/cards/Card";
import { MdAttachMoney } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";

export const DashboardCards = ({data}) => {

  return (
    <div className="dashboard__cards">
      <Card title="Course Fee" descr={"₦"+data?.course_fee} icon={<MdAttachMoney />} />
      <Card title="Amount Paid" descr={data?.amount_paid} icon={<MdAttachMoney />} />
      <Card title="Amount Owing" descr={data?.balance} icon={<MdAttachMoney />} />
      <Card title="Attendance" descr={data?.attendance.progress+"%"} icon={<FiUserCheck />} />
    </div>
  );
};
