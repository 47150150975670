import { useState } from "react";

import { PiTelegramLogoLight } from "react-icons/pi";
export default function ForumTab({ forums, handleReview }) {

  let [value, setValue] = useState("");

  let handleClick = () => {
    handleReview(value);
    setValue("");
  }

  return (
    <div className="module_forum">
      <form className="module_forum_header">
        <input type="text" placeholder="Type post to forum" onChange={e => setValue(e.target.value)} value={value} />
        <button type="button" onClick={handleClick}>{<PiTelegramLogoLight />}</button>
      </form>
      <div className="module_forum_contents">
        {forums?.map((chat) => {
          let type = ""
          if (chat?.type.toUpperCase() == "TRAINER") type = <span className="review-type">Trainer</span>
          if (chat?.type.toUpperCase() == "ADMIN") type = <span className="review-type">Admin</span>

          return <div key={chat.id} className="module_forum_content">
            <div className="module_chat_header">
              <span>{chat.img}</span>
              <h5>{chat.name}  {type}</h5>
              <p>{chat.time}</p>
            </div>
            <p className="module_forum_chat">{chat.descrp}</p>
          </div>
        }

        )}
      </div>
    </div>
  );
}
