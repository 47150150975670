import { IoCodeWorkingSharp } from "react-icons/io5";
import ForumTab from "../ModuleDetails/ForumTab";

function ProjectPhase({phases,forums,handleReview}) {

  return (
    <div className="SP_phases mt-4">
      <div className="SP_phases_tasks">
        {phases?.map((phase) => (
          <div key={phase.id} className="SP_phase">
            <div className="SP_phase_header d-flex justify-content-between">
              <h5>{phase.title}</h5>
              <p>
                <span>Submission Date:</span> {phase.submission_date}
              </p>
            </div>
            <div className="SP_phase_contents">
              {phase.tasks.map((task) => (
                <div className="SP_phase_content" key={task.id}>
                  <div className="SP_phase_descrp d-flex justify-content-between align-items-center">
                    <h5>
                      <span className="sp_phase_content_icon">
                        <IoCodeWorkingSharp />
                      </span>
                      {task.descrp}
                    </h5>
                    <p>{task.status}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="SP_phase_chat">
        <ForumTab forums={forums} handleReview={handleReview}/>
      </div>
    </div>
  );
}

export default ProjectPhase;
