import { useState, useEffect } from "react";
import LoginPayment from "../LoginPayment/LoginPayment";
import { Link } from 'react-router-dom';
import PaymentService from "./PaymentService";

export const PaymentsTable = ({ payments, profile }) => {

  const [paymentActive, setPaymentActive] = useState(false);

  const handlePayment = () => {
    setPaymentActive(!paymentActive);
  };

  const handleOnlinePayment = () => {
    PaymentService.getPaymentLink()
      .then(e => {

      })
  }

  const getClassName = (e) => {
    if (e) {
      let k = e.toLowerCase();
      if (k == "success") return "tr-bg-success";
      if (k == "pending") return "tr-bg-warning";
    }
    return ""
  }

  const normalize = (e) => {
    if (e) {
      let k = e.toLowerCase();
      return k[0].toUpperCase() + k.substring(1)
    }
    return ""
  }

  const format = (e) => {
    let k = new Number(e).toLocaleString();
    return k
  }

  return (
    <div className="payments_table">
      <div className="payments_table_header">
        <button className="button primary" onClick={handlePayment}>
          + Upload Payment
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Method</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.sn}>
              <td className="payments_table_sn">
                <span>{payment.sn}</span>
              </td>
              <td>
                {payment.currency}
                {format(payment.amount)}
              </td>
              <td>{payment.date}</td>
              <td>{normalize(payment?.channel?.replace("_", " "))}</td>
              <td className={getClassName(payment.status)}>{normalize(payment.status)}</td>
              <td>
                {
                  payment.status.toUpperCase() === "SUCCESS" ? <Link to={`/payments/${payment.id}`}>View</Link> : "-"
                }

              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {paymentActive ? (
        <div className="payments_modal">
          <LoginPayment profile={profile} />
          <button onClick={handlePayment} className="payment_modal_close">
            X
          </button>
        </div>
      ) : null}
    </div>
  );
};
