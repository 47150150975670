import { IoAttachSharp, IoCodeSharp } from "react-icons/io5";
import ProjectPhase from "./ProjectPhase";

export default function ({assessment,phases,forums,handleReview}) {
  return (
    <div className="single_project">
      <div className="SP_header1 d-flex justify-content-between align-items-center">
        <div className="SP_header_icon">
          <IoCodeSharp />
        </div>
        <button className="button grey">{assessment.status}</button>
      </div>
      <div className="SP_header2">
        <h2 className="my-2">{assessment.title}</h2>
        <div className="SP_header_21 d-flex justify-content-between">
          <div className="SP_header_sub">
            <span>Duration:</span> {assessment.duration}
          </div>
          <div className="SP_header_sub">
            <span>Start Date:</span> {assessment.start_date}
          </div>
          <div className="SP_header_sub">
            <span>End Date:</span> {assessment.end_date}
          </div>
        </div>
      </div>
      <div className="SP_header3">
        <h5>Project Instruction:</h5>
        <p>
          {assessment.descp}
        </p>
        <button className="button primary-outline SP_header3_btn">
          <span>
            <IoAttachSharp />
          </span>
          Download Resources
        </button>
      </div>
      <ProjectPhase phases={phases} forums={forums} handleReview={handleReview}/>
    </div>
  );
}
