import { useState, useEffect,useRef } from "react";
import EditHeader from "./EditHeader";
import editIcon from "../../assets/img/edit.svg";
import Modal from "../../components/profile/Modal";
import { ProfileData } from "../../context/ProfileContext";
import ProfileService from "./ProfileService";

export default function ProfileAbout({user_id, profile,setProfile}) {
  const { about } = ProfileData();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickBio = ()=>{
    let data ={ bio:profile.bio };
    
    ProfileService.updateProfile(user_id,data)
    .then(e=>{

      if(e.type === "SUCCESS"){
        setIsOpen(false)
      }

    });

  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  return (
    <div className="profile-about">
      <EditHeader heading={"Bio"}>
        <button className="icon" onClick={() => setIsOpen(true)}>
          <img src={editIcon} alt="Edit" />
          <span>Edit</span>
        </button>
      </EditHeader>
      <div className="profile-about-text">
        {about ? (
          <div className="profile-about-bio">
            <p>{about}</p>
          </div>
        ) : (
          <button className="social-link-edit" onClick={() => setIsOpen(true)}>
            <img src={editIcon} alt="Edit" />
            <span>Add a short summary about yourself</span>
          </button>
        )}
      </div>
      {isOpen && (
        <Modal setIsOpen={setIsOpen} title={"Add Bio"} onClick={handleClickBio}>
          <div className="modal--sub-heading">
            <p>Write a short description about yourself, experiences, achievements, skills, or previous job experiences.</p>
          </div>
          <form className="bio-form">
            <div className="form-group">
              <label htmlFor="Bio">Bio</label>
              <textarea value={profile.bio} onChange={(e)=> setProfile({...profile,bio:e.target.value})}></textarea>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
