import { Navigation } from "../../components/navigation/Navigation";
import Details from "./Details";
import ModuleOutline from "./ModuleOutline";
import "../CourseModules/style.css";

function ModuleDetails() {
  return (
    <>
      <Navigation title="Module Details" />
      <div className="space-top db__height">
        <div className="mod_details">
          {/* <Details />
          <ModuleOutline /> */}
        </div>
      </div>
    </>
  );
}

export default ModuleDetails;
