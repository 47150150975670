
import { useState } from "react";
import { IoCodeSharp, IoCloudUploadOutline, IoAttachSharp } from "react-icons/io5";

export default function Assignment({activeTab}) {

  let [upload,setUpload] = useState("")
  
    return <div className="lesson_details--activity">
      <div className="LD_activity--header">
        <div className="LD_activity-icon">
          <IoCodeSharp />
        </div>
        <h4>{activeTab.type}</h4>
      </div>
      <div className="LD_activity_content">
        <h2>{activeTab.title}</h2>
        <h5>Instruction:</h5>
        <p>{activeTab.description}</p>
      </div>
      <div className="LD_activity_footer">
        {activeTab?.resources ? (
          <div className="LD_activity_resource">
            <h5>Resources:</h5>
            <button>
              <IoAttachSharp /> Download Resources
            </button>
          </div>
        ) : null}
        <div className="LD_activity_upload">
          <h5>Submission:</h5>
          <form className="LD_activity_form">
            <div>
              <label htmlFor="activitySubmission">
                <IoCloudUploadOutline />
                {upload ? upload : "Upload"}
              </label>
              <input type="file" id="activitySubmission" onChange={(e) => setUpload(e.target.files[0].name)} />
            </div>
          </form>
        </div>
      </div>
    </div>
  }
