import { Link } from "react-router-dom";
import { IoAttachSharp, IoPersonCircleOutline, IoPaperPlaneOutline } from "react-icons/io5";
import { useState } from "react";

function LessonDetailsForum() {
  const [forumChat] = useState([
    {
      id: 1,
      name: "Michael Jordan",
      time: "1",
      content:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum facere consequatur aliquid nobis nostrum impedit natus voluptatibus dignissimos harum beatae!",
      designantion: "Student",
    },
    {
      id: 2,
      name: "Rita Peters",
      time: "1",
      content:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum facere consequatur aliquid nobis nostrum impedit natus voluptatibus dignissimos harum beatae!",
      designantion: "Trainer",
    },
    {
      id: 3,
      name: "Obi Marcus",
      time: "1",
      content:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum facere consequatur aliquid nobis nostrum impedit natus voluptatibus dignissimos harum beatae!",
      designantion: "Student",
    },
  ]);
  return (
    <div className="lesson_details_forum">
      {/* <div className="LDF_header">
        <h4>Class Forum</h4>
        <Link to="/">
          <IoAttachSharp />
          Whatsapp Group Link
        </Link>
      </div>
      <form className="LDF_form">
        <textarea placeholder="Type to send a chat"></textarea>
        <button className="LDF_form--btn">
          <IoPaperPlaneOutline />
        </button>
      </form>
      <div className="LDF_chat">
        {forumChat.length ? (
          <div className="LDF_chat_container">
            {forumChat.map((chat) => (
              <div className="LDF_chat--content" key={chat.id}>
                <div className="LDF_chat_header">
                  <IoPersonCircleOutline />
                  <p>{chat.time}hr ago</p>
                </div>
                <h5 className="LDF_chat--name">
                  {chat.name} - <span>({chat.designantion})</span>
                </h5>
                <p className="LDF_chat--message">{chat.content}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Be the first to begin a chat</p>
        )}
      </div> */}
    </div>
  );
}

export default LessonDetailsForum;
