import { Navigation } from "../../components/navigation/Navigation";
import AttendanceInWeeks from "./AttendanceInWeeks";
import AttendanceSummary from "./AttendanceSummary";
import {useEffect,useState} from  "react";
import { PageRedirect, PageTitle, Route } from "react-paget";
import "./style.css";

import { GlobalData } from "../../context/GlobalContext";
import AttendanceService from "./AttendanceService";
import Loading from '../../components/Loading'

function Attendance() {

 
  let [record,setRecord] = useState({
    progress:0,
    total_classes: 0,
    total_attended: 0,
    total_weeks: 0,
    total_missed:0,
    today:{
      date:"",
      status:"",
      active:false
    },
    attendance:[
      {
        id: 1,
        week: "Week 1",
        days: [
          { day: "Mon", status: "present" },
          { day: "Tue", status: "present" },
          { day: "Wed", status: "present" },
          { day: "Fri", status: "present" },
        ]
      }
    ]
  });

  let { store } = GlobalData();
  let detail = store?.DETAIL;
  let user_id = detail?.id
  let [page_load,setPageLoad] = useState(true);

  useEffect(()=>{

   if(user_id){

    AttendanceService.getAttendance(user_id)
    .then(e =>{
      setPageLoad(false);
      setRecord({...record,...e});
    })

   }

  },[store]);

  return (
    <PageTitle title="Portal-Attendance">
      <Navigation title={"Attendance"} />
      {
        page_load ? <Loading/> : <div className="db__height">
        <div className="attendance_container">
          <AttendanceSummary summary={record}/>
          <AttendanceInWeeks attendance={record?.attendance} />
        </div>
      </div>
      }
    </PageTitle>
  );
}

export default Attendance;
