import { useEffect, useState } from 'react';

const useCountdown = (start_date,end_date) => {
    let today =   new Date(start_date).getTime();
    let countDownDate = new Date(end_date).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - today
    );

    useEffect(() => {
        const interval = setInterval(() => {
            let e= countDownDate - Date.now();

            setCountDown(e);
            
        }, 1000);
     

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export { useCountdown };
