import { useState, useEffect } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import { ProjectCard } from "../../components/cards/Card";
import { GlobalData } from "../../context/GlobalContext";
import ProjectService from "./ProjectService";
import { PageRedirect, PageTitle, Route } from "react-paget";

import "./style.css";
import Loading from "../../components/Loading";

function Projects() {
  let [projects, setProject] = useState([
    {
      id: 1,
      status: "CURRENT",
      title: "HTML & CSS Assessment",
      module: "Assessment",
      start_date: "16/07/2023",
      end_date: "21/07/2023",
      resp: "Not approved",
      descrp: "Lorem ipsum dolor sit amet consectetur adipisicing elit. In doloremque voluptatem autem. Quo, asperiores odio. Modi et vero provident sit!",
      task_count: 3,
      task_pending: 3,
      progress: 0,
    }
  ]);

  let [type] = useState("projects");
  let { store } = GlobalData();
  let detail = store?.DETAIL;
  let student_user_id = detail?.id;
  let [page_load, setPageLoad] = useState(true);

  useEffect(() => {


    if (student_user_id) {

      ProjectService
        .get(student_user_id, type)
        .then(e => {

          setPageLoad(false)

          let data = e?.map(x => ({
            id: x.id,
            project_id: x.project_id,
            module: x.module,
            status: x.status,
            resp: x.status_text,
            title: x.title,
            start_date: x.start_date,
            end_date: x.end_date,
            task_count: x.total_task,
            task_pending: x.total_pending,
            descrp: x.descp,
            progress: x.progress

          }));

          setProject(data);

        })
    }

  }, [store]);

  return (

    <PageRedirect condition={store} route={store ? Route.PREV_PAGE : "/"}>
      <PageTitle title="Portal-Project">
        <Navigation title="Projects" />
        {
          page_load ? <Loading /> : <div className="db__height">
            <div className="projects_container">
              {projects.map((project) => (
                <ProjectCard project={project} key={project.id} />
              ))}
            </div>
          </div>
        }
      </PageTitle>
    </PageRedirect>
  );
}

export default Projects;
