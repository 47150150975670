import { useState,useEffect } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import { GlobalData } from "../../context/GlobalContext";
import "./style.css";
import NotificationService from "./NotificationService";
import { PageRedirect, Route,PageTitle } from "react-paget";
import Loading from "../../components/Loading";

function Notifications() {

  
  const [notifications,setNotification] = useState([
    {
      id: 1,
      title: "Holiday Notice",
      descrp:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum, atque porro. Sint atque reprehenderit veniam velit architecto libero quo tempora nulla illo nemo perferendis hic sunt officiis voluptatibus, mollitia harum! Inventore tenetur et libero autem magnam voluptatem repellat, cum obcaecati odit doloribus, eveniet ratione unde explicabo ab quis praesentium perspiciatis a harum optio dolorem perferendis. Atque ad voluptatibus, aspernatur, dolorum quibusdam ut omnis accusantium error nostrum minus aperiam qui eum esse sequi vero nesciunt hic delectus et perferendis accusamus molestias sit aut alias? Nobis optio error amet ducimus labore! Autem aliquam, perferendis voluptates laboriosam a nam quos ab eius mollitia?",
      date: new Date().toLocaleDateString(),
    }
  ]);

  let { store } = GlobalData();
  let [status,setStatus] = useState(true);
  let detail = store?.DETAIL;
  let [page_load,setPageLoad] = useState(true);
 

  useEffect(()=>{
    let user_id = detail?.id;
    if(user_id){
      getNotice(user_id);
    }

  },[store]);

  let handleReadNotice =(id) =>{
    NotificationService.markAsRead(id)
    .then(() => {
        setStatus(!status);
        getNotice(user_id);
        alert("Successfully read");
    });
  }

  let getNotice = (user_id) =>{
    NotificationService.getNotice(user_id)
    .then(e => {
   
      setPageLoad(false)
      let data = e.data?.map(x => ({
        id:x.id,
        title:x.title,
        descrp:x.descp,
        is_read:x.is_read,
        date:x.created_at
      }));

      setNotification(data);

    })
  }

  return (
    <PageTitle title="Portal-Notice">
      <Navigation title="Notifications" />
      {
        page_load ? <Loading/> : <div className="db__height">
        <div className="notifications">
          <div className="notifications_header">
            <h5>All Notifications</h5>
          </div>
          <div className="notifications_contents">
            {notifications.map((notification) => (
              <div className="notification_content" key={notification.id}>
                <div className="notification_content_sub">
                  <h4>{notification.title}</h4>
                  <h5>{notification.date} <button className={`btn btn-primary btn-notice`} onClick={()=>handleReadNotice(notification.id)} disabled={notification.is_read}>Read</button></h5>
                </div>
                <p>{notification.descrp}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      }
    </PageTitle>
  );
}

export default Notifications;
