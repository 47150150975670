import { useState, useContext, createContext } from "react";


export const GlobalContext = createContext();
export const GlobalData = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {

  const [store, setStore] = useState('');

    const values = { 
    store,
    setStore
  };

  return <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>;
};
