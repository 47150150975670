import { IoCheckmarkSharp } from "react-icons/io5";
import { PaymentsCard } from "../../components/cards/Card";
import { useState } from "react";

function AttendanceSummary({ summary }) {
  const [attendanceStatus] = useState(true);
  return (
    <div className="attendance_summary">
      <div className="attendance_percentage">
        <PaymentsCard title="Attendance Percentage" descrp={`${summary.progress}%`} />
      </div>
      <div className="attendance_summary_cards">
        <PaymentsCard title="Total Class Weeks" descrp={summary.total_weeks} />
        <PaymentsCard title="Total Class Days" descrp={summary.total_classes} />
        <PaymentsCard title="Total Classes Attended" descrp={summary.total_attended} />
        <PaymentsCard title="Total Classes Missed" descrp={summary.total_missed} />
      </div>
      {
        summary.today ? <div className="attendance_current">
          <h5>Today's Attendance</h5>
          <h6>{summary.today?.date} </h6>
          {summary.today.active ? (
            <button className="button bg-success text-white">
              <IoCheckmarkSharp /> Present
            </button>
          ) : (
            <button className="button bg-danger text-white">
              <IoCheckmarkSharp /> Absent
            </button>
          )}
        </div> : <></>
      }
    </div>
  );
}

export default AttendanceSummary;
