import axios from 'axios';
import config from "../config"

class PaymentService{

    getReceipt(id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/payments/:id/info").bind(id);
       
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getPayment(student_id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/:students_id/payments").bind(student_id);
       
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getPaymentLink(data){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/payments/online");
       
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    getPaymentByTransactionId(id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/payments/:id/transaction_id").bind(id);
       
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

    uploadPayment(user_id,data){

        let formData = new FormData();
        let url = (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/payments/:user_id/upload").bind(user_id);
        
        formData.append("attachment", data);
  
        return fetch(url, {
            method: "POST",
            body: formData,
        }).then((res) => res.json());
       
    };

}

export default new PaymentService;