export const LiveClassTabObjective = () => {
  return (
    <div className="LiveClassTabObjective">
      <h2>What you will Learn</h2>
      <ul>
        <li>What is HTML Tag</li>
        <li>Types of Tag</li>
        <li>Block Tags</li>
        <li>Inline Tags</li>
        <li>Tag Usage</li>
      </ul>
    </div>
  );
};
