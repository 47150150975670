import axios from 'axios';
import config from "../config"

class LessonDetailService {

    getLessons(user_id,module_id,course_id) {
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/online/get-lessons").query({user_id,module_id,course_id});
       
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err.message))
        })

    }

    startLesson(data) {
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/online/start-lesson");
       
        return new Promise((resolve, reject) => {
            axios.post(url,data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err.message))
        })

    }

    setLessonTrackLocation(data) {
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/online/set-lesson-track-location");
       
        return new Promise((resolve, reject) => {
            axios.post(url,data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err.message))
        })

    }

    completeLesson(data) {
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/online/complete-lesson");
       
        return new Promise((resolve, reject) => {
            axios.post(url,data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err.message))
        })

    }

    setNextLesson(data) {
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/online/set-next-lesson");
       
        return new Promise((resolve, reject) => {
            axios.post(url,data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err.message))
        })

    }
}

export default new LessonDetailService;