export default function EditHeader({ heading, children }) {
  return (
    <div className="profile--header">
      <div className="text">
        <p>{heading}</p>
      </div>
      {children}
    </div>
  );
}
