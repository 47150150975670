import axios from 'axios';
import config from "../config"

class PasswordService{

    changePwd(data){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/accounts/change-passwd");
        return new Promise((resolve,reject) => {
         axios.post(url,data)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

}

export default new PasswordService;