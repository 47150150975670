import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { GlobalProvider } from "./context/GlobalContext";
import { ToastContainer } from 'react-toastify';
import {ReactTypeExtender} from "react-type-extender";
import { CookiesProvider } from 'react-cookie';

import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';

import "./styles/root.css";
import "./styles/button.css";
import "./styles/content.css";
import "./styles/form.css";
import "./styles/sidebar.css";
import "./styles/navbar.css";
import "./styles/table.css";
import "./styles/modal.css";
import "./styles/card.css";
import "./styles/media-query.css";
import "./styles/common.css";
import "./styles/progressbar.css";
import './styles/flux.css';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    
    <ReactTypeExtender/>
      <GlobalProvider>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <App />
        </CookiesProvider>
      </GlobalProvider>
      <ToastContainer />
  </StrictMode>
);