
export default function Button(){
    return <div>

        <button className="button primary shadow">Primary</button>
        <button className="button success shadow">Success</button>
        <button className="button danger shadow">Danger</button>
       

    </div>
}
