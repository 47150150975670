import axios from 'axios';
import config from "../config"

class LessonService {

    getModules(user_id,course_id) {
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/courses/module").query({course_id,user_id});
       
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => reject(err.message))
        })

    }

    getCourseProgress(user_id,course_id){
        let url =  (config.MODE=="DEV" ? config.DEV : config.PROD).concat("/students/course-progress").query({user_id,course_id});
        return new Promise((resolve,reject) => {
         axios.get(url)
         .then(res => {
            resolve(res.data);
         })
         .catch(err => reject(err.message));
       })
    }

}

export default new LessonService;