function CertificateDesign({ data, bg, color, border, LogoBg, Logo }) {
  return (
    <div className="certificates_container">
      <div className="certificates" style={{ background: bg, color: color }}>
        <img src={LogoBg} alt="Logo Background" className="logoBg" />
        <div className="certificate_outer_frame" style={{ borderColor: border }}>
          <div className="certificate_frame_box certificate_frame_box1" style={{ borderColor: border, backgroundColor: bg }}></div>
          <div className="certificate_frame_box certificate_frame_box2" style={{ borderColor: border, backgroundColor: bg }}></div>
          <div className="certificate_frame_box certificate_frame_box3" style={{ borderColor: border, backgroundColor: bg }}></div>
          <div className="certificate_frame_box certificate_frame_box4" style={{ borderColor: border, backgroundColor: bg }}></div>
        </div>
        <div className="certificate_frame" style={{ borderColor: border }}>
          <div className="certificate_box certificate_box1" style={{ borderColor: border }}></div>
          <div className="certificate_box certificate_box2" style={{ borderColor: border }}></div>
          <div className="certificate_box certificate_box3" style={{ borderColor: border }}></div>
          <div className="certificate_box certificate_box4" style={{ borderColor: border }}></div>
          <div className="certificate_content_container">
            <div className="certificate_logo">
              <img src={Logo} alt="Logo" />
              <h4 style={{ color: border }}>Rework Academy</h4>
            </div>
            <div className="certificate_content">
              <h3 className="certificate_title">This certificate is awarded to</h3>
              <h2 className="certificate_name">{data?.full_name}</h2>
              <p className="certificate_descrp">upon the successful completion of the course</p>
              <h3 className="certificate_course">{data?.course}</h3>
            </div>
            <div className="certificate_cordinators">
              <div className="certificate_head">
                <div className="certificate_head_divider"></div>
                <i>Director</i>
                <p>Rework Academy</p>
              </div>
              <div className="certificate_head">
                <div className="certificate_head_divider"></div>
                <i>Head of Training</i>
                <p>Rework Academy</p>
              </div>
            </div>
            <div className="certificate_footer">
              <h3>Student ID: 211010037</h3>
              <h3>Certificate ID: {data?.certificate_id}</h3>
              <h3>Date Issued: {data?.date_issued}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CertificateDesign;
