export const LiveClassTabDescription = () => {
  return (
    <div className="LiveClassTabDescription">
      <h2>Working with HTML Tags</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos tenetur vel, porro sed, explicabo quaerat quasi neque fuga consequuntur iure assumenda quis
        quam. Dolores incidunt aliquid quasi enim, quidem, recusandae exercitationem aperiam ad ratione similique excepturi aliquam atque maxime, fugiat voluptates aut
        iusto dignissimos! Voluptatibus, autem velit? Unde ipsa quia deleniti quisquam vel? Quo necessitatibus incidunt laborum nesciunt saepe modi, dicta non aspernatur
        cupiditate adipisci accusantium accusamus iste a. Odio totam dolor eligendi eveniet! Delectus qui nulla nesciunt, incidunt laborum, esse fugit accusamus vero
        eaque sapiente voluptatum? Natus quam aliquid unde ea explicabo porro magnam dolores dolor quod laudantium. Reprehenderit, non tempore rem nihil sunt perferendis
        enim quae modi cupiditate impedit unde architecto excepturi? Expedita neque consectetur temporibus ex itaque esse necessitatibus nostrum a vel, nemo repellendus
        ut autem impedit perferendis eligendi error provident doloremque corporis culpa corrupti? Dolorum atque error velit a doloribus incidunt quo ducimus laudantium
        deleniti iusto.
      </p>
    </div>
  );
};
