export default function Quiz(props) {


  let handleStartQuiz = (args) => {
    props.setQuizEngine(true)
  }

  let handleReview =() =>{
    props.setMode("REVIEW")
  }

  return <div className="qza-wrap">
    <div className="flex">
      <div className="qza-img-right-spacing">
        <img src="https://365datascience.com/resources/assets/images/icon-quiz.png" className="quiz-icon" />
      </div>
      <div>
        <div className="qza-title">Quiz</div>
        <p className="qza-botton-spacing">
          Put your new skills to the test with this <span className="qza-alt-text">time-limited</span>, optional quiz! There’s no minimum score and you can retake it as many times as you want. Gain deeper understanding and see how your score compares to the average.
        </p>
        <div>
          {
            !props.data.completed ?  
            <button 
              className="qza-button primary" 
              onClick={() => handleStartQuiz(props.data)}>
                Start
            </button> :
            <div className="flex">
               <button 
                onClick={() => handleStartQuiz(props.data)}
                className="qza-button primary">Retake quiz</button>
               <button 
                onClick={() => handleReview(props.data)}
                className="qza-button secondary">Review questions</button>
            </div>
          }
         
        </div>
      </div>
    </div>

  </div>
}
