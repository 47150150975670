import "./style.css";
import { useState, useEffect, useRef } from "react";
import { Navigation } from "../../components/navigation/Navigation";
import LessonDetailsForum from "./LessonDetailsForum";
import LessonDetailsContent from "./LessonDetailsContent";

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import LessonDetailService from './LessonDetailService';
import { GlobalData } from "../../context/GlobalContext";
import video1 from "../../assets/video/video1.mp4";

import { VideoData } from "../../context/VideoContext";

import Loading from '../../components/Loading'
import Render from './Render'
import QuizEngine from './QuizEngine'


function LessonDetail() {

  let { name, url } = useParams()
  let { store } = GlobalData();
  let init_video_data = [
    {
      id: 1,
      title: "HTML & CSS",
      current: true,
      lectures: [
        {
          id: 1,
          title: "Introduction to the Web",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "lesson",
          completed: false,
          current: true,
          locked: false,
        },
        {
          id: 2,
          title: "Introduction to HTML Tags-quiz",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "quiz",
          quiz_id: "123",
          completed: true,
          current: false,
          locked: false,
        },
        {
          id: 3,
          title: "More on Tags",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "lesson",
          completed: false,
          current: false,
          locked: true,
        },
        {
          id: 4,
          title: "Introduction to CSS",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "lesson",
          completed: false,
          current: false,
          locked: true,
        },
        {
          id: 5,
          title: "Create a Loan Application",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "assignment",
          resources: true,
          completed: false,
          current: false,
          locked: true,
        },
      ],
    },
    {
      id: 2,
      title: "HTML & CSS Lab Class",
      current: false,
      lectures: [
        {
          id: 6,
          title: "Introduction to the Web",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: "web.mp4",
          duration: 10,
          type: "lesson",
          completed: false,
          locked: true,
        },
        {
          id: 7,
          title: "Introduction to HTML Tags",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: "web.mp4",
          duration: 10,
          type: "lesson",
          completed: false,
          locked: true,
        },
        {
          id: 8,
          title: "More on Tags",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: "web.mp4",
          duration: 10,
          type: "lesson",
          completed: false,
          locked: true,
        },
        {
          id: 9,
          title: "Introduction to CSS",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: "web.mp4",
          duration: 10,
          type: "lesson",
          completed: false,
          locked: true,
        },
        {
          id: 10,
          title: "Tyes of Styling",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: "web.mp4",
          duration: 10,
          type: "assignment",
          completed: false,
          locked: true,
        },
      ],
    },
    {
      id: 3,
      title: "Git & GitHub",
      current: false,
      lectures: [
        {
          id: 11,
          title: "Introduction to the Web",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "lesson",
          completed: false,
          locked: true,
        },
        {
          id: 12,
          title: "Introduction to HTML Tags",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "lesson",
          completed: false,
          locked: true,
        },
        {
          id: 13,
          title: "More on Tags",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "lesson",
          completed: false,
          locked: true,
        },
        {
          id: 14,
          title: "Introduction to CSS",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "lesson",
          completed: false,
          locked: true,
        },
        {
          id: 15,
          title: "Tyes of Styling",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi impedit iure repudiandae suscipit vitae voluptas maiores eos autem, esse velit!",
          video: video1,
          duration: 10,
          type: "classwork",
          completed: false,
          locked: true,
        },
      ],
    }
  ];

  let navigate = useNavigate()
  let [mode, setMode] = useState("Quiz")

  let [activeTab, setActiveTab] = useState({});
  let [nextTab, setNextTab] = useState({})
  let [videos, setVideos] = useState(init_video_data);
  let [upload, setUpload] = useState("");
  let [searchParams] = useSearchParams()
  let {
    options,
    setVideoUrl,
    setOptions,
    playlist,
    setPlaylist } = VideoData()

  let video_track_location;

  let detail = store?.DETAIL;
  let is_loaded = useRef(false);
  let init_value = 5;
  // let countdown = init_value;

  let [page_load, setPageLoad] = useState(true);
  let [isQuizEngine, setQuizEngine] = useState(false)

  let user_id = detail?.id
  let course_id = detail?.course?.id;
  let module_id = searchParams.get("id");

  // let a = "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
  // let b = "http://sample.vodobox.net/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8"

  // let a = "https://static.vecteezy.com/system/resources/previews/023/607/690/mp4/vdo-mp4-helix-human-dna-3-d-rendering-video.mp4"
  // let b = "https://static.vecteezy.com/system/resources/previews/003/321/288/mp4/covid-19-illustration-in-3d-animation-video.mp4"



  useEffect(() => {

    if (store) {

      user_id = detail?.id
      course_id = detail?.course?.id;

      //  if (user_id && !is_loaded.current)
      if (user_id && !is_loaded.current) {

        // is_loaded.current = true;

        LessonDetailService
          .getLessons(user_id, module_id, course_id)
          .then(res => {

            if (res.type === "EMPTY") {
              // lesson has not been set for student
            } else {

              let d = res.current_lesson;
              let playlist = []
              let active = "";
              let index = 0;

              let arr = res?.data?.map(e => ({
                id: e.id,
                title: e.title,
                lectures: e.lessons.map(k => ({
                  id: k.id,
                  title: k.title,
                  url: k.route?.replace("/", ""),
                  chapter_id: k.chapter_id,
                  description: k.descp,
                  resource_url: k.resource_url,
                  duration: k.resource_duration,
                  type: k.lesson_type,
                  completed: k.status === "WATCHED" ? true : false,
                  locked: k.is_locked,
                  next_lesson_id: k.next_lesson_id,
                  started: k.started,
                  started_at: k.started_at,
                }))
              }))

              let info = {
                ...options,
                sources: [{
                  src: d.resource_url,
                  type: "application/x-mpegURL"
                }]
              }

              arr?.map(e => {
                e.lectures.map((k, idx) => {

                  if (k.url === url) {
                    index = idx
                    active = k;
                  }

                  playlist.push({
                    url: k.url,
                    sources: [{
                      src: k.resource_url,
                      type: "application/x-mpegURL"
                    }]
                  })

                })
              })

              setPlaylist(playlist)

              setPageLoad(false)

              setVideos([...arr])
              setVideoUrl(d.resource_url)

              setOptions(info)
              // set the startime of the video if watched
              // setStarTime(d.watched_track_location)

              if (active) setActiveTab({ ...activeTab, ...active })

            }

          })
      }

    }

  }, [store, page_load])

  function nextContent(idx) {
    let _url;
    let active = ""
    let content=""
    let lindex = localStorage.getItem('idx')

    if(!idx) idx = 0

    if(!lindex) idx = 0;
    else idx = parseInt(lindex,10) + 1

    if (idx >= playlist.length) idx = playlist.length - 1

    localStorage.setItem("idx",idx)

    _url = playlist[idx].url

    videos?.map(e => {
      e.lectures.map((k, idx) => {

        if (k.url === _url) {

          active = k;
        }


      })
    })

    content =  playlist[idx].sources
  
    setActiveTab({ ...activeTab, ...active })

    navigate(`/lessons/${name}/${_url}?id=${module_id}`)

    return content

  }

  return (
    <>
      <Navigation title={activeTab.title} />
      {page_load ? <Loading /> :
        <div className="Lesson_detail">
          <div className="lesson_details_container">
            <div>
            
              {
                !isQuizEngine ? (
                  <Render
                    data={videos}
                    nextContent={nextContent}
                    type={activeTab.type}
                    mode={mode}
                    setQuizEngine={setQuizEngine}
                    setMode={setMode}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                ) : <QuizEngine
                  name={name}
                  mode={mode}
                  nextTab={nextTab}
                  setMode={setMode}
                  // setNextTab={setNextTab}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  setQuizEngine={setQuizEngine} />
              }
              <LessonDetailsForum />
            </div>
            <LessonDetailsContent
              name={name}
              module_id={module_id}
              url={url}
              setActiveTab={setActiveTab}
              setNextTab={setNextTab}
              activeTab={activeTab}
              module_lesson={videos} />
          </div>
        </div>
      }
    </>
  );
}

export default LessonDetail;
