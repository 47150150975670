import { IoAttachSharp } from "react-icons/io5";

export const ProjectDownload = ({project}) => {
 
  return (
    <div className="project__card project__card1">
      <h2>{project?.title}</h2>
      <div>(Duration: {project.duration})</div>
      <div>Date: {project?.phases[0].start_date} - {project?.phases[0].end_date}</div>
      <span></span>

      <h4>Brief Description</h4>
      <p>
       {project?.descp}
      </p>

      <h4 className="mt-4">Task</h4>
      <ul>
        {
          project?.phases[0].tasks?.map(e=> <li key={e.id}>- {e.task}</li>)
        }
      </ul>

      <a 
        href={project?.phases[0].resource_links} 
        download 
        className="button primary shadow">
        <IoAttachSharp />
        Click here to download resources
      </a>
    </div>
  );
};
